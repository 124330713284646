// 复制/删除模型弹窗 需要外部传入vue实例this
export const actionModelConfirm = function (title = '确认复制该模型吗？', okType = 'primary') {
    return new Promise(resolve => {
        this.$confirm({
            title,
            okType,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                resolve()
            },
            onCancel() {
            }
        })
    })
}
