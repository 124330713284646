/**
 * 规则引擎--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 数据核验模型
 * */
// 规则引擎-数据核验模型-添加数据核验模型
export const RULE_ENGINE_ADD_DATA_CHECK_MODEL_BUTTON = 3220
// 规则引擎-数据核验模型-复制数据核验模型
export const RULE_ENGINE_COPY_DATA_CHECK_MODEL_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-删除数据核验模型详情
export const RULE_ENGINE_DELETE_DATA_CHECK_MODEL_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-列表逻辑删除数据核验模型
export const RULE_ENGINE_LOGIC_DELETE_DATA_CHECK_MODEL_BUTTON = 'RULE_ENGINE_LOGIC_DELETE_DATA_CHECK_MODEL_BUTTON'
// 规则引擎-数据核验模型-数据核验模型详情-提交数据核验模型详情
export const RULE_ENGINE_SUBMIT_DATA_CHECK_MODEL_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-编辑基本信息
export const RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-添加布局分类
export const RULE_ENGINE_DATA_CHECK_MODEL_ADD_LAYOUT_CATEGORY_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-修改布局分类
export const RULE_ENGINE_DATA_CHECK_MODEL_EDIT_LAYOUT_CATEGORY_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-删除布局分类
export const RULE_ENGINE_DATA_CHECK_MODEL_DELETE_LAYOUT_CATEGORY_BUTTON = 3220
// 规则引擎-数据核验模型-数据核验模型详情-删除风险因子
export const RULE_ENGINE_DATA_CHECK_MODEL_DELETE_RISK_FACTOR_BUTTON = 3220
/**
 * 风险因子
 * */
// 规则引擎-风险因子-添加风险因子
export const RULE_ENGINE_ADD_RISK_FACTOR_BUTTON = 3200
// 规则引擎-风险因子-删除风险因子
export const RULE_ENGINE_DELETE_RISK_FACTOR_BUTTON = 3200
// 规则引擎-风险因子-查看风险因子属性值
export const RULE_ENGINE_LOOK_RISK_FACTOR_ATTRIBUTE_BUTTON = 3200
/**
 * 基础规则*/
// 规则引擎-风险因子-添加风险因子
export const RULE_ENGINE_ADD_BASE_RULE_BUTTON = 'RULE_ENGINE_ADD_BASE_RULE_BUTTON'
// 规则引擎-风险因子-查看风险因子
export const RULE_ENGINE_LOOK_BASE_RULE_BUTTON = 'RULE_ENGINE_LOOK_BASE_RULE_BUTTON'
// 规则引擎-风险因子-复制风险因子
export const RULE_ENGINE_COPY_BASE_RULE_BUTTON = 'RULE_ENGINE_COPY_BASE_RULE_BUTTON'
// 规则引擎-风险因子-编辑风险因子
export const RULE_ENGINE_EDIT_BASE_RULE_BUTTON = 'RULE_ENGINE_EDIT_BASE_RULE_BUTTON'
// 规则引擎-风险因子-删除风险因子
export const RULE_ENGINE_DELETE_BASE_RULE_BUTTON = 'RULE_ENGINE_DELETE_BASE_RULE_BUTTON'
/**
 * 风控模型
 * */
// 规则引擎-风险因子-添加风险因子
export const RULE_ENGINE_ADD_RISK_CONTROL_BUTTON = 3200
// 规则引擎-风险因子-编辑风险因子
export const RULE_ENGINE_EDIT_RISK_CONTROL_BUTTON = 3200
/**
 * 风险评估模型
 * */
// 规则引擎-风险评估模型-添加风险评估模型
export const RULE_ENGINE_ADD_RISK_ASSESSMENT_CONTENT_BUTTON = 3200
// 规则引擎-风险评估模型-复制风险评估模型
export const RULE_ENGINE_COPY_RISK_ASSESSMENT_MODEL_BUTTON = 'RULE_ENGINE_COPY_RISK_ASSESSMENT_MODEL_BUTTON'
// 规则引擎-风险评估模型-删除风险评估模型
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_BUTTON = 3200
// 规则引擎-风险评估模型-逻辑删除风险评估模型
export const RULE_ENGINE_LOGIC_DELETE_RISK_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_LOGIC_DELETE_RISK_ASSESS_MODEL_BUTTON'
// 规则引擎-风险评估模型-提交风险评估模型
export const RULE_ENGINE_SUBMIT_RISK_ASSESSMENT_BUTTON = 3200
// 规则引擎-风险评估模型-基本信息-编辑基本信息
export const RULE_ENGINE_RISK_ASSESSMENT_EDIT_BASIC_INFO_BUTTON = 3200
// 规则引擎-风险评估模型-布局分类-添加布局分类
export const RULE_ENGINE_RISK_ASSESSMENT_ADD_LAYOUT_CATEGORY_BUTTON = 3200
// 规则引擎-风险评估模型-布局分类-编辑布局分类
export const RULE_ENGINE_RISK_ASSESSMENT_EDIT_LAYOUT_CATEGORY_BUTTON = 3200
// 规则引擎-风险评估模型-布局分类-删除布局分类
export const RULE_ENGINE_RISK_ASSESSMENT_DELETE_LAYOUT_CATEGORY_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-查看评估内容
export const RULE_ENGINE_RISK_LOOK_ASSESSMENT_CONTENT_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-编辑评估内容
export const RULE_ENGINE_RISK_EDIT_ASSESSMENT_CONTENT_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-删除评估内容
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-添加风险评估内容-全国业务-插入字段
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_ALL_CAN_USED_INSERT_FIELD_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-添加风险评估内容-地区业务-详情
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_INFO_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-添加风险评估内容-地区业务-编辑
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_EDIT_BUTTON = 3200
// 规则引擎-风险评估模型-评估内容-添加风险评估内容-地区业务-删除
export const RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_DELETE_BUTTON = 3200

/**
 * 风险评分模型
 * */
// 规则引擎-评分模型-添加评分模型
export const RULE_ENGINE_ADD_GRADE_MODEL_BUTTON = 3200
// 规则引擎-评分模型-复制评分模型
export const RULE_ENGINE_COPY_GRADE_MODEL_MODEL_BUTTON = 'RULE_ENGINE_COPY_GRADE_MODEL_MODEL_BUTTON'
// 规则引擎-风险评分模型-编辑
export const RULE_ENGINE_EDIT_GRADE_MODEL_INFO_BUTTON = 3200
// 规则引擎-风险评分模型-评分模型详情-删除
export const RULE_ENGINE_DELETE_GRADE_MODEL_INFO_BUTTON = 3200
// 规则引擎-风险评分模型-评分模型详情-删除
export const RULE_ENGINE_LOGIC_DELETE_GRADE_MODEL_BUTTON = 'RULE_ENGINE_LOGIC_DELETE_GRADE_MODEL_BUTTON'
// 规则引擎-风险评分模型-评分模型详情-提交审核
export const RULE_ENGINE_SUBMIT_GRADE_MODEL_INFO_BUTTON = 3200
// 规则引擎-风险评分模型-评分模型详情-评分因子tab-编辑评分因子
export const RULE_ENGINE_GRADE_MODEL_INFO_EDIT_GRADE_FACTOR_BUTTON = 3200
// 规则引擎-风险评分模型-评分模型详情-评分因子tab-删除评分因子
export const RULE_ENGINE_GRADE_MODEL_INFO_DELETE_GRADE_FACTOR_BUTTON = 3200
/**
 * 风险等级
 * */
// 规则引擎-风险因子-添加风险因子
export const RULE_ENGINE_ADD_RISK_CLASS_BUTTON = 'RULE_ENGINE_ADD_RISK_CLASS_BUTTON'
// 规则引擎-风险因子-查看风险因子
export const RULE_ENGINE_LOOK_RISK_CLASS_INFO_BUTTON = 'RULE_ENGINE_LOOK_RISK_CLASS_INFO_BUTTON'
// 规则引擎-风险因子-编辑风险因子
export const RULE_ENGINE_EDIT_RISK_CLASS_BUTTON = 'RULE_ENGINE_EDIT_RISK_CLASS_BUTTON'
// 规则引擎-风险因子-删除风险因子
export const RULE_ENGINE_DELETE_RISK_CLASS_BUTTON = 'RULE_ENGINE_DELETE_RISK_CLASS_BUTTON'
// 规则引擎-风险因子-提交审核
export const RULE_ENGINE_SUBMIT_RISK_CLASS_MODEL_BUTTON = 'RULE_ENGINE_SUBMIT_DATA_CHECK_MODEL_BUTTON'

/**
 * 智能评估模型
 * */
// 规则引擎-数据核验模型-添加智能评估模型
export const RULE_ENGINE_ADD_SMART_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_ADD_SMART_ASSESS_MODEL_BUTTON'
// 规则引擎-数据核验模型-复制智能评估模型
export const RULE_ENGINE_COPY_SMART_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_COPY_SMART_ASSESS_MODEL_BUTTON'
// 规则引擎-数据核验模型-智能评估模型-删除智能评估模型详情
export const RULE_ENGINE_DELETE_SMART_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_DELETE_SMART_ASSESS_MODEL_BUTTON'
// 规则引擎-数据核验模型-智能评估模型-逻辑删除智能评估模型详情
export const RULE_ENGINE_LOGIC_DELETE_SMART_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_LOGIC_DELETE_SMART_ASSESS_MODEL_BUTTON'
// 规则引擎-数据核验模型-智能评估模型-提交智能评估模型详情
export const RULE_ENGINE_SUBMIT_SMART_ASSESS_MODEL_BUTTON = 'RULE_ENGINE_SUBMIT_SMART_ASSESS_MODEL_BUTTON'

/**
 * 规则集合
 * */
// 提交审核
export const RULE_ENGINE_SUBMIT_RULE_SET_MODEL_BUTTON = 'RULE_ENGINE_SUBMIT_RULE_SET_MODEL_BUTTON'
// 删除模型
export const RULE_ENGINE_DELETE_RULE_SET_MODEL_BUTTON = 'RULE_ENGINE_DELETE_RULE_SET_MODEL_BUTTON'
// 编辑规则集基础信息
export const RULE_ENGINE_EDIT_RULE_SET_BASE_INFO_BUTTON = 'RULE_ENGINE_EDIT_RULE_SET_BASE_INFO_BUTTON'
// 删除
export const RULE_ENGINE_DELETE_RULE_SET_BUTTON = 'RULE_ENGINE_DELETE_RULE_SET_BUTTON'
