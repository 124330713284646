import { Select as ASelect } from 'ant-design-vue'
export default {
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        clear: {
            type: Boolean,
            default: true
        },
        value: {
            type: [String, Number]
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
                this.$emit('blur')
            }
        }
    },
    methods: {
        handleChange() {
            this.$emit('change')
        }
    }
}
