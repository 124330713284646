import getAreaTreeService from '@service/getAreaTreeService'
import { COUNTRY_CODE } from '@constant/Const'
import { getMap } from '@utils/toTree'

export default {
    data() {
        return {
            mapArea: new Map()
        }
    },
    methods: {
        /**
         * 获取省市区名字
         * */
        getFullArea(code) {
            let name = ''
            let item = this.mapArea.get(code)
            if (!item) return
            while (item && item.parent) {
                name = item.label + name
                item = item.parent
            }
            name = item.label + name
            return name
        },
        /**
         * 获取省市地区名字
         * */
        getAreaName(map, code) {
            const res = {}
            const currentItem = map.get(code)
            if (!currentItem) {
                this.$message.error(`${code}找不到`)
                res.provinceName = ''
                res.cityName = code
                return res
            }

            if (currentItem.parent) {
                res.provinceName = currentItem.parent.label
                res.cityName = currentItem.label
            } else {
                res.provinceName = currentItem.label
                res.cityName = ''
            }
            return res
        },
        /**
         * 获取地区接口数据
         * */
        async getAreaList() {
            try {
                let data = await getAreaTreeService()
                const res = data.filter(item => item.value !== COUNTRY_CODE)
                // 树形转map
                this.mapArea = getMap(res)
            } catch (e) {
                this.$message.error(e.message)
            }
        }

    }
}
