import downloadFileBlob from '@utils/downloadFileBlob'

// const reg = /^.*filename="?([^"]+)"?.*$/

/**
 * 通过axios下载文件
 * @param axiosResult
 */
export default function downloadFileByBlob(axiosResult, isDecode) {
    const { data, headers } = axiosResult || {}
    const contentDisposition = headers['content-disposition'] || ''
    const contentType = headers['content-type']
    let fileName = contentDisposition.split('fileName=')[1]
    if (isDecode) {
        fileName = decodeURI(contentDisposition.split(';')[1].split('filename=')[1])
    }
    downloadFileBlob(data, contentType, fileName)
}
