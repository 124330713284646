/**
* 加法
* */
export const accAdd = function (a, b) {
    let c, d, e
    try {
        c = a.toString().split('.')[1].length // 获取a的小数位长度
    } catch (err) {
        c = 0
    }
    try {
        d = b.toString().split('.')[1].length // 获取b的小数位长度
    } catch (err) {
        d = 0
    }
    // 先求e，把a、b 同时乘以e转换成整数相加，再除以e还原
    return e = Math.pow(10, Math.max(c, d)), (accMul(a, e) + accMul(b, e)) / e
}

/**
 * 减法
 * */
export const accLoss = function (a, b) {
    let c, d, e
    try {
        c = a.toString().split('.')[1].length // 获取a的小数位长度
    } catch (err) {
        c = 0
    }
    try {
        d = b.toString().split('.')[1].length // 获取b的小数位长度
    } catch (err) {
        d = 0
    }
    // 和加法同理
    return e = Math.pow(10, Math.max(c, d)), (accMul(a, e) - accMul(b, e)) / e
}
/**
 * 乘法
 * */
export const accMul = function (a, b = 100) {
    let c = 0,
        d = a.toString(), // 转换为字符串
        e = b.toString() // ...
    try {
        c += d.split('.')[1].length // c 累加a的小数位长度
    } catch (err) {
        console.log(err)
    }
    try {
        c += e.split('.')[1].length // c 累加b的小数位长度
    } catch (err) {
        console.log(err)
    }
    // 转换为整数相乘，再除以10^c ,移动小数点，还原，利用整数相乘不会丢失精度
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}
/**
 * 除法
 * */
export const accDiv = function (a, b = 100) {
    let c, d, e = 0,
        f = 0
    try {
        e = a.toString().split('.')[1].length
    } catch (err) {
        console.log(err)
    }
    try {
        f = b.toString().split('.')[1].length
    } catch (err) {
        console.log(err)
    }
    // 同理，转换为整数，运算后，还原
    c = Number(a.toString().replace('.', ''))
    d = Number(b.toString().replace('.', ''))
    return accMul(c / d, Math.pow(10, f - e))
}
