<template>
    <a-card class="page-panel" :bordered="false">
        <slot v-if="$slots.title" slot="title" name="title" />
        <slot v-if="$slots.extra" slot="extra" name="extra" />
        <slot />
    </a-card>
</template>

<script>
import { Card as ACard } from 'ant-design-vue'

export default {
    name: 'PagePanel',
    components: {
        ACard
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.page-panel {
  &:not(:last-child) {
    margin-bottom: $padding;
  }
}
</style>
