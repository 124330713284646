// 模型 相关按钮展示

import { DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT, DATA_CHECK_MODEL_AUDIT_STATE_REJECT, DATA_CHECK_MODEL_AUDIT_STATE_REFUSE } from '@constant/enum'
export default {
    // 提交审核: 未提交审核/审核不通过
    canSubmitAudit(auditState) {
        return auditState === DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REFUSE
            || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REJECT
    },
    // 编辑: 未提交审核和审核不通过可以删除
    canEdit(auditState) {
        return auditState === DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT
            || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REFUSE || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REJECT
    },
    // 删除: 未提交审核和审核不通过可以删除
    canDelete(auditState) {
        return auditState === DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT
            || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REFUSE || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REJECT
    },
    // 添加:未提交审核或者审核未通过
    canAdd(auditState) {
        return auditState === DATA_CHECK_MODEL_AUDIT_STATE_REFUSE
            || auditState === DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT || auditState === DATA_CHECK_MODEL_AUDIT_STATE_REJECT
    },
    canAction(auditState) {
        return this.canDelete(auditState)
    }
}
