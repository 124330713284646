<template>
    <page-layout class="list-page-layout"
                 :header="header"
                 :loading="loading"
                 :title="title"
                 :full="full"
                 :show-loading="showLoading"
                 :show-title="showTitle"
    >
        <slot slot="title" name="title" />
        <slot slot="headerContent" name="headerContent" />
        <slot slot="headerAction" name="headerAction" />
        <!-- 内容 -->
        <div class="list-page-layout__inner">
            <div v-if="$slots.header"
                 class="list-page-layout__inner-header"
            >
                <slot name="header" />
            </div>
            <slot />
        </div>
    </page-layout>
</template>

<script>
import PageLayout from '@layout/PageLayout'

export default {
    name: 'PagePanel',
    components: {
        PageLayout
    },
    props: {
        header: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        full: {
            type: Boolean,
            default: false
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.list-page-layout {
  &__inner-header {
    margin-bottom: $space-col-base;
  }
}
</style>
