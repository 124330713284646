import { DATA_CHECK_MODEL_AUDIT_STATE_PASS } from '@constant/enum'

export default {
    components: {
        ConfirmDelete: () => import('@weights/modal/ConfirmDelete')
    },
    data() {
        return {
            deleteProp: {
                visible: false,
                id: ''
            },
            hasDeletePermission: false // 删除权限
        }
    },
    methods: {
        // 展示逻辑删除按钮
        showDelete({ auditState }) {
            return this.hasDeletePermission && auditState === DATA_CHECK_MODEL_AUDIT_STATE_PASS
        },
        handleDelete(id) {
            this.deleteProp = {
                visible: true,
                id
            }
        }
    }
}
