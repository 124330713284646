import {
    ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
    ORDER_BUSINESS_STATE_SING_STATE_TYPE,
    ORDER_STATE_AWAIT_ENTER_STATE_TYPE,
    ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE,
    ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE,
    ORDER_BUSINESS_STATE_REJECT_STATE_TYPE,
    ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE,
    ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE,
    ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE,
    ORDER_STATE_NOT_RISK_CONTROL_STATE_TYPE,
    REPORT_FACTOR_ID,
    REPORT_FACTOR_CODE,
    FINANCIAL_ID,
    FINANCIAL_CODE,
    REPORT_CODE,
    REPORT_ID,
    ORDER_RISK_PROCUREMENT_TYPE_ENUM_LIST,
    IS_PUSH_ORDER_INFO_LIST
} from '@constant/enum'
import auditAwaitEnterTaskService from '@service/task/auditAwaitEnterTaskService'
import auditAwaitFirstAuditService from '@service/task/auditAwaitFirstAuditService'
import auditAwaitAgainAuditService from '@service/task/auditAwaitAgainAuditService'
import auditAwaitHighAuditService from '@service/task/auditAwaitHighAuditService'

// 初始状态
export const isInitState = function (businessState) {
    return businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE
}
// 待录入
export const isAwaitEnter = function (info) {
    const { businessState, state } = info
    return isInitState(businessState) && state === ORDER_STATE_AWAIT_ENTER_STATE_TYPE
}
// 初审
export const isInitAudit = function (info) {
    const { businessState, state } = info
    return isInitState(businessState) && state === ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE
}

// 复审
export const isAgainAudit = function (info) {
    const { businessState, state } = info
    return isInitState(businessState) && state === ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE
}
// 高审
export const isHeightAudit = function (info) {
    const { businessState, state } = info
    return isInitState(businessState) && state === ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE
}

// 已签发
export const isFinish = function (info) {
    const { businessState } = info
    return businessState === ORDER_BUSINESS_STATE_SING_STATE_TYPE
}
// 拒保
export const isRejectInsure = function (info) {
    const { businessState } = info
    return businessState === ORDER_BUSINESS_STATE_REJECT_STATE_TYPE
}
// 订单关闭
export const isCloseOrder = function (info) {
    const { businessState } = info
    return businessState === ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE
}
// 撤销
export const isRevocation = function (info) {
    const { businessState } = info
    return businessState === ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE
}
// 不走风控订单
export const isNotRiskControlState = function (obj) {
    return obj && obj.state === ORDER_STATE_NOT_RISK_CONTROL_STATE_TYPE
}
/**
 * 可以下载报告：已签发,拒保
 * */
export const canDownLoadReport = function (info) {
    return orderStateBoolFnObj.isFinish(info) || orderStateBoolFnObj.isRejectInsure(info)
}

// 返回各个状态bool的函数对象
export const orderStateBoolFnObj = {
    isInitState,
    isAwaitEnter,
    isInitAudit,
    isAgainAudit,
    isHeightAudit,
    isFinish,
    isRejectInsure,
    isCloseOrder,
    isRevocation,
    canDownLoadReport
}

// 财务报表
export const showFinancial = function (item) {
    const { dataVerificationFactorCode, masterDataClassificationId } = item
    return masterDataClassificationId === FINANCIAL_ID &&
        dataVerificationFactorCode === FINANCIAL_CODE
}
// 征信报告
export const showReport = function (item) {
    const { dataVerificationFactorCode, masterDataClassificationId } = item
    return masterDataClassificationId === REPORT_ID &&
        dataVerificationFactorCode === REPORT_CODE
}
// 是否提供征信报告
export const isSelectReport = function (item) {
    const { dataVerificationFactorCode, masterDataClassificationId } = item
    return masterDataClassificationId === REPORT_FACTOR_ID &&
        dataVerificationFactorCode === REPORT_FACTOR_CODE
}

export const showFinAndReportFnObj = {
    showFinancial,
    showReport,
    isSelectReport
}

export const auditServeFn = function (riskOrderDetailDTO) {
    let serveFn = null
    if (orderStateBoolFnObj.isAwaitEnter(riskOrderDetailDTO)) {
        serveFn = auditAwaitEnterTaskService
    } else if (orderStateBoolFnObj.isInitAudit(riskOrderDetailDTO)) {
        serveFn = auditAwaitFirstAuditService
    } else if (orderStateBoolFnObj.isAgainAudit(riskOrderDetailDTO)) {
        serveFn = auditAwaitAgainAuditService
    } else if (orderStateBoolFnObj.isHeightAudit(riskOrderDetailDTO)) {
        serveFn = auditAwaitHighAuditService
    }
    return serveFn
}

// 属于风控采购订单
export const isRiskProcurement = function (messageType) {
    return ORDER_RISK_PROCUREMENT_TYPE_ENUM_LIST.find(item => item.value === messageType)
}
// 属于订单详情
export const isPushOrderInfo = function (messageType) {
    return IS_PUSH_ORDER_INFO_LIST.find(item => item.value === messageType)
}
