/**
 * 商品管理--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 商品管理-产品分类-添加分类
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_ADD_CATEGORY_BUTTON = 3220
// 商品管理-产品分类-编辑分类
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_EDIT_CATEGORY_BUTTON = 3220
// 商品管理-产品分类-查看下级分类
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_LOOK_CATEGORY_BUTTON = 3220
// 商品管理-产品分类-删除分类
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_DELETE_CATEGORY_BUTTON = 3220
// 商品管理-产品分类-保前参数
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_PARAMS_SETTING_BUTTON = 3220
// 商品管理-产品分类-设置险种编号
export const COMMODITY_MANAGE_COMMODITY_CATEGORY_SET_INSURE_TYPE_BUTTON = 3220

// 商品管理-参数列表-添加参数
export const COMMODITY_MANAGE_ADD_PARAMS_BUTTON = 3220
// 商品管理-参数列表-删除参数
export const COMMODITY_MANAGE_DELETE_PARAMS_BUTTON = 3220
// 商品管理-参数列表-修改参数
export const COMMODITY_MANAGE_EDIT_PARAMS_BUTTON = 3220

// 商品管理-商品列表-复制商品
export const COMMODITY_MANAGE_COPY_COMMODITY_BUTTON = 3220
// 商品管理-商品列表-删除商品详情
export const COMMODITY_MANAGE_DELETE_COMMODITY_BUTTON = 3220
// 商品管理-商品列表-逻辑删除商品
export const COMMODITY_MANAGE_LOGIC_DELETE_COMMODITY_BUTTON = 'COMMODITY_MANAGE_LOGIC_DELETE_COMMODITY_BUTTON'
// 商品管理-商品列表-编辑商品详情
export const COMMODITY_MANAGE_EDIT_COMMODITY_BUTTON = 3220
// 商品管理-商品列表-商品详情-风控模型信息-查看
export const COMMODITY_MANAGE_RISK_CONTROL_LOOK_BUTTON = 3220


