export const IS_IMG_ENUM_LIST = [
    'png',
    'jpg',
    'jpeg',
    'bmp',
    'gif',
    'webp',
    'psd',
    'svg',
    'tiff'
]
export const IS_EXCEL_ENUM_LIST = [
    'xlsx',
    'docx',
    'xls',
    'doc'
]
export const IS_FILE_ENUM_LIST = [
    ...IS_IMG_ENUM_LIST,
    ...IS_EXCEL_ENUM_LIST,
    'pdf',
    'zip'
]

// 转化ENUM_LIST到文件上传支持的accept属性 '.png,.zip'
export const getAcceptFromEnum = function (enumList) {
    return enumList.map(item => '.' + item).join(',')
}
export const isImg = function (ext) {
    return IS_IMG_ENUM_LIST.includes(ext)
}
export const isPdf = function (ext) {
    return ext === 'pdf'
}
export const isExcel = function (ext) {
    return IS_EXCEL_ENUM_LIST.includes(ext)
}
export const isZip = function (ext) {
    return ext === 'zip'
}

// 校验上传文件格式
export const isAcceptFile = function (ext, enumList) {
    return enumList.includes(ext)
}


/**
    获取文件后缀名
* */
export const getFileExt = function (pathName) {
    const index = pathName.lastIndexOf('.')
    return pathName.substr(index + 1)
}

// 获取文件名
export const getFileName = function (pathName) {
    const index = pathName.lastIndexOf('.')
    return pathName.substr(0, index)
}
