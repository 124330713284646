<script>
import {
    Col as ACol,
    FormModel as AFormModel,
    Input as AInput,
    Button as AButton
} from 'ant-design-vue'

export default {
    name: 'CustomButton',
    components: {
        ACol,
        AFormModelItem: AFormModel.Item,
        AInput,
        AButton
    },
    props: {
    // span
        span: {
            type: Number,
            required: false,
            default: 6
        },
        // 表单label
        label: {
            type: String,
            required: true
        },
        // prop
        prop: {
            type: String,
            required: true
        },
        // placeholder
        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        labelSpan: {
            type: Number,
            required: false,
            default: 4
        },
        wrapperSpan: {
            type: Number,
            required: false,
            default: 18
        }
    },
    computed: {
        props() {
            const { $props } = this
            const { ...props } = $props
            return props
        }
    },
    methods: {},
    render(h) {
        return h('a-col', {
            props: {
                span: this.span
            }
        }, [
            h('a-form-model-item', {
                props: {
                    label: this.label,
                    labelCol: {
                        span: this.labelSpan
                    },
                    wrapperCol: {
                        span: this.wrapperSpan
                    },
                    prop: this.prop
                }
            }, [this.$slots.formElements])
        ])
    }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
  align-items: center;
}
</style>
