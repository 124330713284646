import getAreaTreeService from '@service/getAreaTreeService'
import { convertTreeToEntities } from './core'

// 地区数组转树形
export function toTree(data) {
    let result = []
    if (!Array.isArray(data)) {
        return result
    }
    data.forEach(item => {
        delete item.children
    })
    let map = {}
    data.forEach(item => {
        map[item.areaCode] = item
    })
    data.forEach(item => {
        let parent = map[item.parentCode]
        if (parent) {
            (parent.children || (parent.children = [])).push(item)
        } else {
            result.push(item)
        }
    })
    return result
}


/**
 * 数组转map
 * */
export const getMap = function (...arg) {
    return convertTreeToEntities(...arg)
}
/**
 * 获取地区数据
 * */
export const getAreaData = async function () {
    return await getAreaTreeService()
}
/**
 * 获取地区map数据
 * */
export const getAreaMap = async function () {
    return getMap(await getAreaData())
}


/**
 * 根据value找到级联的name
 * */
export const getParentNames = function (areaMap, value, name = []) {
    const item = areaMap.get(value)
    name.unshift(item.label)
    if (item.parentId) {
        getParentNames(areaMap, item.parentId, name)
    }
    return name
}

/**
 * 根据value找到级联的ids[a,b,c]
 * */
export const getParentIds = function (map, code) {
    let parentIds = []// parentIds = []
    if (code === null || !code) return
    let item = map.get(code)
    // 有的是中文回显
    if (!item) return
    while (item && item.parent) {
        parentIds.unshift(item.value)
        item = item.parent
    }
    parentIds.unshift(item.value)
    return parentIds
}
/**
 * 获取所有子集
 */
export const getChildIds = function (map, value, childIds = []) {
    const item = map.get(value)
    if (item && item.children) {
        item.children.forEach(item2 => {
            childIds.push(item2.value)
            getChildIds(map, item2.value, childIds)
        })
    }
}


/**
 * @params data 级联数组
 * @params value 级联id
 * @params name 数据结构对应的label字段名
 * @params id 数据结构对应的value字段名
 * @return 级联数据某id对应的name 'a-b-c'
 * */
export const getCascaderName = function (data, value, name, id) {
    const map = getMap(data, name, id)
    let current = map.get(value)
    if (!current) return null
    let res = [current.label]
    while (current && current.parent) {
        current = current.parent
        res.unshift(current.label)
    }
    return res.join('-')
}
/**
 *  递归至树形 兼容antd children为null时报错
 * */
export const recursionToTree = function (arr, label = 'name', value = 'id') {
    let res = []
    for (let i = 0; i < arr.length; i++) {
        let obj = {}
        const item = arr[i]
        if (!item.children) {
            obj = {
                label: item[label],
                value: item[value]
                // children: null
            }
        } else {
            obj = {
                label: item[label],
                value: item[value],
                children: recursionToTree(item.children, label, value)
            }
        }
        res.push(obj)
    }
    return res
}

// 终极方案：tree to map and tree(过滤children为null)
export function getMapAndTree(data, $name = 'label', $value = 'value') {
    const map = new Map()

    function fn(data, $name, $value, parentId) {
        const tree = []
        data.forEach(item => {
            let obj = {}

            const { [$name]: label, [$value]: value, children } = item
            const mapItem = {
                parentId,
                parent: parentId && map.get(parentId),
                [$name]: label,
                [$value]: value,
                children
            }
            map.set(value, mapItem)
            if (children && children.length) {
                fn(children, $name, $value, value)

                obj = {
                    label: item[$name],
                    value: item[$value],
                    children: recursionToTree(item.children, $name, $value)
                }
            } else {
                obj = {
                    label: item[$name],
                    value: item[$value]
                }
            }
            tree.push(obj)
        })
        return tree
    }

    const tree = fn(data, $name, $value, null)
    return {
        map,
        tree
    }
}
