<template>
    <div class="steps-container">
        <slot />
    </div>
</template>
<script>
export default {
    name: 'StepsContainer'
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.steps-container {
  padding: 25px 140px;
  background: #fff;
  margin-bottom: $padding;
}
</style>
