import { scrollConfig } from '@constant/Const'
import { Table as ATable } from 'ant-design-vue'
import { DEFAULT_PAGES_SIZE } from '@config'
import tableShowTotal from '@mixins/tableShowTotal'
export default {
    mixins: [tableShowTotal],
    data() {
        return {
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            dataList: [],
            scrollConfig
        }
    },
    components: {
        ATable
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        }
    }
}
