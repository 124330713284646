<!-- 评分计算方式 -->
<template>
    <a-select
        v-model="modelValue"
        placeholder="请选择"
        :allow-clear="clear"
        @change="handleChange">
        <a-select-option
            v-for="item in enumList"
            :key="item.value"
            :value="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
import selectMixin from '../selectMixin'

import { WEIGHT_ENUM_LIST } from '@constant/enum'
export default {
    name: 'ScoreCardSelect',
    mixins: [selectMixin],
    data() {
        return {
            enumList: WEIGHT_ENUM_LIST
        }
    }
}
</script>
