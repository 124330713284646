/**
 * 订单管理--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 订单列表--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 订单列表-导出
export const ORDER_MANAGE_ORDER_LIST_EXPORT_ORDER_LIST_BUTTON = 3200
// 订单列表-查看
export const ORDER_MANAGE_ORDER_LIST_LOOK_ORDER_LIST_BUTTON = 3200
// 订单列表-审核
export const ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON = 'ORDER_MANAGE_ORDER_LIST_AUDIT_ORDER_BUTTON'
// 订单列表-重签审核
export const ORDER_MANAGE_ORDER_LIST_AUDIT_RESIGN_BUTTON = 'ORDER_MANAGE_ORDER_LIST_AUDIT_RESIGN_BUTTON'
// 订单详情-重签审核
export const ORDER_MANAGE_ORDER_INFO_AUDIT_RESIGN_BUTTON = 'ORDER_MANAGE_ORDER_INFO_AUDIT_RESIGN_BUTTON'

/**
 * 订单详情--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 订单详情-详细信息-黑名单信息-查看
export const ORDER_MANAGE_ORDER_INFO_ORDER_DETAIL_INFO_BLACK_LIST_VIEW_BUTTON = 3200
// 订单详情-详细信息-风险提示-查看
export const ORDER_MANAGE_ORDER_INFO_ORDER_DETAIL_INFO_RISK_WARNING_VIEW_BUTTON = 3200

// 订单详情-详细信息-风控信息-查看报表
export const ORDER_MANAGE_ORDER_INFO_RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON = 3200
// 订单详情-下载报告
export const ORDER_MANAGE_ORDER_INFO_DOWN_LOAD_REPORT_BUTTON = 3200
