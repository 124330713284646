/**
 * 管理首页--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 消息中心--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 消息中心-一键已读
export const HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON = 'HOME_PAGE_MESSAGE_CENTER_ALL_READED_BUTTON'
// 消息中心-设为已读
export const HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON = 'HOME_PAGE_MESSAGE_CENTER_SET_READED_BUTTON'
