/**
 * 风控采购--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 订单列表--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

// 查看
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_VIEW_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_VIEW_BUTTON'

// 编辑
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_EDIT_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_EDIT_BUTTON'
// 再次下单
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_AGAIN_ORDER_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_AGAIN_ORDER_BUTTON'

// 退单
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON'

// 下载报告
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON'

// 承保
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON'
// 报告重签
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_REPORT_RESIGN_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_REPORT_RESIGN_BUTTON'

// 导出列表
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_BUTTON'
// 导出承保记录
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_UNDERWRITE_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_UNDERWRITE_BUTTON'
// 导入承保结果
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_IMPORT_UNDERWRITE_BUTTON = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_IMPORT_UNDERWRITE_BUTTON'

/** =====================暂存====================*/
export const RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_EDIT_BUTTON = 'RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_EDIT_BUTTON'
export const RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_DELETE_BUTTON = 'RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_DELETE_BUTTON'
