<!-- 评分卡 -->
<template>
    <a-select
        v-model="modelValue"
        placeholder="请选择"
        :allow-clear="clear"
        @change="handleChange">
        <a-select-option
            v-for="item in SCORE_CARD_ENUM_LIST"
            :key="item.value"
            :value="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
import {
    SCORE_CARD_ENUM_LIST
} from '@constant/enum'
import selectMixin from '../selectMixin'

export default {
    name: 'ScoreCardSelect',
    mixins: [selectMixin],
    data() {
        return {
            SCORE_CARD_ENUM_LIST
        }
    }
}
</script>
