<template>
    <a-tabs :default-active-key="defaultActiveKey" class="component-tabs-panel" @change="handleTabsChange">
        <a-tab-pane
            v-for="item in tabsData"
            :key="item.key"
            :tab="item.tab" />
    </a-tabs>
</template>

<script>
import { Tabs as ATabs } from 'ant-design-vue'

export default {
    name: 'TabsPanel',
    components: {
        ATabs,
        ATabPane: ATabs.TabPane
    },
    props: {
        tabsData: {
            type: Array,
            default() {
                return []
            }
        },
        defaultActiveKey: {
            type: String,
            default() {
                return ''
            }
        }
    },
    methods: {
        handleTabsChange(key) {
            this.$emit('handle-tabs-change', key)
        }
    }
}
</script>

<style lang="scss">
.component-tabs-panel {
  .ant-tabs-bar {
    margin: 0;
  }
}
</style>
