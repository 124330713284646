<template>
    <div class="image-card">
        <div class="image-card__img-wrapper">
            <img v-if="showImg"
                 class="image-card__img"
                 :src="item.filePath"
                 alt="alt"
                 @click="handleImg">
            <a-icon v-else-if="showPdf" type="file-pdf" @click="handlePdf" />
            <a-icon v-else-if="showExcel" type="file-excel" @click="handleDown" />
            <a-icon v-else-if="showZip" type="file-zip" @click="handleDown" />
        </div>
        <div class="image-card__title">{{ item.fileName }}</div>
    </div>
</template>

<script>
import { Icon as AIcon } from 'ant-design-vue'
import { getFileExt, isImg, isPdf, isExcel, isZip } from '@utils/file'
export default {
    name: 'ImageCard',
    components: {
        AIcon
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        alt: {
            type: String,
            default() {
                return ''
            }
        }
    },
    computed: {
        fileExt() {
            const { filePath } = this.item
            return filePath && getFileExt(filePath)
        },
        showImg() {
            return isImg(this.fileExt)
        },
        showPdf() {
            return isPdf(this.fileExt)
        },
        showExcel() {
            return isExcel(this.fileExt)
        },
        showZip() {
            return isZip(this.fileExt)
        }
    },
    methods: {
        handleImg() {
            this.$emit('img', this.item)
        },
        handlePdf() {
            this.$emit('pdf', this.item)
        },
        handleDown() {
            window.open(this.item.filePath)
        }
    }
}
</script>

<style lang="scss">
.image-card {
  width: 200px;
  box-sizing: border-box;
  margin-bottom: 20px;
  text-align: center;

  &__img-wrapper {
    width: 200px;
    height: 120px;
    border-radius: 2px;
    padding: 2px;
    cursor: pointer;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #333;
    line-height: 20px;
    margin-top: 15px;
  }

  .anticon {
    font-size: 120px
  }
}
</style>
