const phoneReg = /^1[3456789]\d{9}$/

// 验证邮箱
export const validateEmail = (rule, value, callback) => {
    const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的邮箱地址`
        callback(new Error(errorMsg))
    }
}

// 验证统一社会信用代码
export const validateSocialCreditCode = (rule, value, callback) => {
    const reg = /^[A-Z0-9]{18}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的统一社会信用代码`
        callback(new Error(errorMsg))
    }
}

// 验证手机号有效性
export const validatePhoneNumber = (rule, value, callback) => {
    if (!value || phoneReg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的手机号`
        callback(new Error(errorMsg))
    }
}
// 验证url有效性
export const validateUrl = (rule, value, callback) => {
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入有效的url地址`
        callback(new Error(errorMsg))
    }
}
// 验证座机有效性 手机
export const validateLandline = (rule, value, callback) => {
    const reg = /(^0\d{2,3}-[1-9]\d{6,7}$)|(^(400)-(\d{3})-(\d{4})(.)(\d{1,4})$)|(^(400)-(\d{3})-(\d{4}$))|(^(800)-(\d{3})-(\d{4})(.)(\d{1,4})$)|(^(800)-(\d{3})-(\d{4}$))/
    if (!value || reg.test(value) || phoneReg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的手机号/座机号`
        callback(new Error(errorMsg))
    }
}
// 验证身份证有效性
export const validateIDCard = (rule, value, callback) => {
    const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的身份证号`
        callback(new Error(errorMsg))
    }
}


// 验证input只输入金额
export const validateNumber = (rule, value, callback) => {
    const reg = /^[-]?(0|[1-9](\d)*)(\.\d{1,2})?$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的金额`
        callback(new Error(errorMsg))
    }
}

// 验证input只输入整数
export const validatePositiveNumber = (rule, value, callback) => {
    // const reg =/^[0-9]*$/
    const reg = /^[0-9]+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的整数`
        callback(new Error(errorMsg))
    }
}

// 验证输入时间
export const validateDate = (rule, value, cb) => {
    const regDate = /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/
    if (regDate.test(value)) {
        cb()
    }
    cb(new Error('请输入正确的时间格式'))
}

// 属性值
export const validateAttribute = (rule, value, callback) => {
    // const reg =/^[0-9]*$/
    const reg = /^0{1}([.]\d{1,2})?$|^[1-9]\d*([.]{1}[0-9]{1,2})?$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的数值`
        callback(new Error(errorMsg))
    }
}
// 属性值2 支持负数
export const validateNegativeAttribute = (rule, value, callback) => {
    const reg = /^-?0{1}([.]\d{1,3})?$|^-?[1-9]\d*([.]\d{1,3})?$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的数值`
        callback(new Error(errorMsg))
    }
}
// 风险等级名字
export const validateClassName = (rule, value, callback) => {
    const reg = /^\w[+-]?$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的风险等级`
        callback(new Error(errorMsg))
    }
}

// 权重：大于0 两位小数
export const validateWeight = (rule, value, callback) => {
    const reg = /^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的数值`
        callback(new Error(errorMsg))
    }
}

// 动态浮点数 默认2位
export const customFloatFn = function (fieldPrecision = 2) {
    const reg = new RegExp('^-?\\d+(.\\d{' + fieldPrecision + '})?$')
    return (rule, value, callback) => {
        if (!value || reg.test(value)) {
            callback()
        } else {
            // 错误信息
            const errorMsg = `请输入正确的浮点数`
            callback(new Error(errorMsg))
        }
    }
}
