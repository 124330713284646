export default function downloadFileBlob(res, type, filename) {
    const blob = new Blob([res], {
        type
    })
    const link = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    // 下载链接
    link.href = href
    link.download = filename
    link.click()
    window.URL.revokeObjectURL(href)
}
