/**
 * 表格分页-展示文本
 */
export default {
    methods: {
        /**
         * @param range
         * @param total
         * @returns {(function(*=, *=): string)|(function(*=, *=): boolean)|string}
         */
        tableShowTotal(total, range) {
            return `第${range[0]}-${range[1]}条/总共${total}条`
        }
    }
}
