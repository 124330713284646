/**
 * 转换树形结构为链表
 * @param treeNodes
 * @param $name 解析外部传入数据结构的对应label的字段名
 * @param $id 解析外部传入数据结构的对应value的字段名
 * @returns {Map<any, any>}
 */
export function convertTreeToEntities(treeNodes, $name = 'label', $id = 'value') {
    const keyEntities = new Map()
    traverseTreeNodes(treeNodes, item => {
        const { value, parentId, label } = item
        const entity = {
            value,
            parentId,
            label
        }
        keyEntities.set(value, entity)
        entity.parent = keyEntities.get(parentId)
        if (entity.parent) {
            entity.parent.children = entity.parent.children || []
            entity.parent.children.push(entity)
        }
    }, $name, $id)

    return keyEntities
}


/**
 *
 * @param treeNodes
 * @param callback
 */
function traverseTreeNodes(treeNodes, callback, $name, $id) {
    /**
     *
     * @param node
     * @param parentId
     */
    function processNode(node, parentId, $name, $id) {
        const childList = node ? node.children : treeNodes
        let id = null
        if (node) {
            const { [$id]: value, [$name]: label } = node
            const data = {
                value,
                label,
                parentId
            }
            id = value
            callback(data)
        }

        childList && childList.length && childList.forEach(subNode => {
            processNode(subNode, id, $name, $id)
        })
    }

    processNode(null, null, $name, $id)
}


export const toMap = function (data, $name = 'label', $value = 'value') {
    const map = new Map()

    function fn(data, $name, $value, parentId) {
        data.forEach(item => {
            const { [$name]: label, [$value]: value, children } = item
            const mapItem = {
                parentId,
                parent: parentId && map.get(parentId),
                [$name]: label,
                [$value]: value,
                children
            }
            map.set(value, mapItem)
            if (children && children.length) {
                fn(children, $name, $value, value)
            }
        })
    }

    fn(data, $name, $value, null)
    return map
}
