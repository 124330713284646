<template>
    <div class="table-panel">
        <div v-if="$slots.title||$slots.expand" class="table-panel__header">
            <div class="table-panel__header-title">
                <slot name="title" />
            </div>
            <div v-if="$slots.expand" class="table-panel__header-expand">
                <slot name="expand" />
            </div>
        </div>
        <div class="table-panel__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TablePanel'
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .table-panel {
        padding: $small-padding 0;

        &__header,
        &__content {
            padding: $small-padding 0;
        }

        &__header {
            display: flex;

            &-title {
                flex: 1;
            }
        }
    }
</style>
