export const scrollConfig = {
    x: 1100,
    y: 500
}

// 名字input 输入框最长字符
export const INPUT_MAX_LENGTH = 50
// 审核意见输入框500字符
export const INPUT_MAX_LENGTH_500 = 500
// inputArea 输入框最长字符
export const INPUT_AREA_MAX_LENGTH = 1000
// input内容 输入框最长字符
export const INPUT_AREA_CONTENT_MAX_LENGTH = 5000

// 规则引擎上传文件最大
export const OSS_PACKAGE_TYPE_RULE_ENGINE_MAX_SIZE = 10
// 风控采购上传文件最大
export const OSS_PACKAGE_TYPE_RISK_STOCK_MAX_SIZE = 100

// 全国的code
export const COUNTRY_CODE = '000000000000'

// pageSize 最大
export const PAGE_SIZE_MAX_LENGTH = 100

// 分类id 建设工程投标
export const RISK_CATEGORY_ID_BID = '3373751078156015622'
export const isRiskCategoryBid = function (id) {
    return id === RISK_CATEGORY_ID_BID
}
