<template>
    <div class="search-panel" :class="[expansion,layoutClassName]">
        <div class="search-panel__inner">
            <!-- 标题 -->
            <div class="search-panel__title">
                <slot v-if="$slots.title" name="title" />
                <template v-else>筛选查询</template>
            </div>
            <!-- 左侧主体 -->
            <div class="search-panel__body">
                <a-form-model ref="form"
                              layout="horizontal"
                              :model="model"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol">
                    <div class="search-panel__main">
                        <a-row :gutter="[16, 16]" style="display: flex; flex-wrap: wrap;">
                            <!-- 默认显示部分 -->
                            <slot />

                            <!-- 展开部分 -->
                            <slot v-if="$slots.expansion && expansion" name="expansion" />

                            <!-- 操作 -->
                            <a-col :span="6" :offset="actionOffset" :class="actionClassName">
                                <custom-button text="重置" type="reset" @click="handleRefresh" />
                                <a-button type="primary" @click="handleSearch">搜索</a-button>
                                <template v-if="$slots.expansion">
                                    <a-button type="link" @click="handleExpansion">
                                        {{ expansionText }}
                                        <a-icon class="search-panel__arrow-icon"
                                                type="down"
                                        />
                                    </a-button>
                                </template>
                            </a-col>
                        </a-row>
                    </div>
                </a-form-model>
            </div>
        </div>
    </div>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton,
    Icon as AIcon,
    Row as ARow,
    Col as ACol
} from 'ant-design-vue'
import CustomButton from '@components/Button'

export default {
    name: 'ComponentsSearchPanel',
    components: {
        AFormModel,
        AButton,
        AIcon,
        ARow,
        ACol,
        CustomButton
    },
    props: {
        layout: {
            type: String,
            default: 'horizontal'
        },
        model: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            expansion: false,
            labelCol: {
                span: 8
            },
            wrapperCol: {
                span: 16
            },
            actionAbsolute: false,
            actionRelative: false,
            actionOffset: 0
        }
    },
    computed: {
        expansionText() {
            const { expansion } = this
            return expansion ? '收起' : '展开'
        },
        layoutClassName() {
            const { layout } = this
            return layout
        },
        actionClassName() {
            const { actionAbsolute, actionRelative } = this
            return [
                'search-panel__action', {
                    'search-panel__action-absolute': actionAbsolute,
                    'search-panel__action-relative': actionRelative
                }
            ]
        }
    //
    },
    mounted() {
    // 计算操作按钮定位位置
        this.calcActionPosition()
    },
    methods: {
    /**
     * 计算操作按钮定位位置
     */
        calcActionPosition() {
            this.slotLength = this.$slots.expansion ? this.$slots.expansion.length + this.$slots.default.length : this.$slots.default.length
            if (!this.expansion) { // 收起
                this.actionAbsolute = true
                this.actionRelative = false
                this.actionOffset = 0
            } else { // 展开
                if (this.slotLength % 4 === 0) { // 展开4的倍数
                    this.actionAbsolute = false
                    this.actionRelative = true
                    this.actionOffset = 18
                } else { // 展开非4的倍数
                    this.actionAbsolute = true
                    this.actionRelative = false
                    this.actionOffset = 0
                }
            }
        },
        handleSearch() {
            this.$emit('search')
        },
        handleRefresh() {
            this.$refs.form.resetFields()
            this.$emit('refresh')
        },
        handleExpansion() {
            this.expansion = !this.expansion
            // 计算操作按钮定位位置
            this.calcActionPosition()
        },
        handleFormReset() {
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

$search-panel-prefix: search-panel;
.search-panel {
  position: relative;
  padding: $space-col-lg;
  background: $component-background;
  border-radius: $border-radius-base;
  //box-shadow: $component-box-shadow;
  overflow-x: scroll;

  &.vertical {
    .#{$search-panel-prefix}__inner {
      display: block;
    }

    .#{$search-panel-prefix}__body {
      margin-right: 0;
    }

    .#{$search-panel-prefix}__action {
      text-align: right;
    }
  }

  &__inner {
    display: flex;
    min-width: $min-width;
  }

  &__body {
    flex: 1;
    margin-right: $padding;

    .ant-form-item {

      margin-bottom: 0;

      & > .ant-form-item-label {
        line-height: 32px;
        //width: auto;
      }

      .ant-form-item-control {
        //line-height: 32px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }
  }

  &__expansion {
    /*display: none;*/
    .ant-form-item {
      margin-top: $small-padding;
    }
  }


  &__main {
    .ant-row:not(:first-child) {
      margin-top: $space-sm;
    }

    .ant-select-selection__rendered {
      line-height: 32px;
    }
  }

  &__action {
    position: relative;

    .ant-btn:not(:last-child) {
      margin-right: $padding;
    }
  }

  &__action-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__action-relative {
    position: relative;
  }

  &__arrow-icon {
    transition: all .3s !important;
  }

  // 展开后样式
  &.expansion {
    .#{$search-panel-prefix}__arrow-icon {
      transform: rotate(180deg) translateY(2px);
    }

    .#{$search-panel-prefix}__expansion {
      display: block;
    }
  }

  &__title {
    font-size: $font-size-md;
    font-weight: 500;
    color: #000;
    padding-bottom: $padding;
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-label {
      min-width: 70px;
    }

    // label默认颜色过重
    .ant-form-item-label > label {
      color: inherit;
      text-align: left;
    }
  }
}
</style>
