<template>
    <div class="page-header">
        <!-- 面包屑 -->
        <div v-if="breadcrumb" class="page-header__breadcrumb">
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link to="/">首页</router-link>
                </a-breadcrumb-item>
                <template v-for="(item,index) in breadcrumb"
                >
                    <a-breadcrumb-item v-if="showBreadcrumbItem(item)"
                                       :key="item.path"
                    >
                        <template>
                            <span v-if="index===breadcrumb.length-1">{{ item.meta.title }}</span>
                            <router-link v-else :to="item.path">{{ item.meta.title }}</router-link>
                        </template>
                    </a-breadcrumb-item>
                </template>
            </a-breadcrumb>
        </div>
        <!-- 标题 -->
        <!-- 判断是否有title部分插槽 -->
        <template v-if="hasTitle && $slots.action">
            <div class="page-header__title-box">
                <div v-if="hasTitle">
                    <slot v-if="$slots.title" name="title" />
                    <h2 v-else class="page-header__title">{{ title }}</h2>
                </div>
                <!-- 操作 -->
                <div v-if="$slots.action" class="page-header__action">
                    <slot name="action" />
                </div>
            </div>
        </template>
        <div v-if="$slots.default" class="page-header__main">
            <!-- 主体内容 -->
            <div class="page-header__content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'

export default {
    name: 'PageHeader',
    components: {
        ABreadcrumb,
        ABreadcrumbItem: ABreadcrumb.Item
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        breadcrumb: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            hasTitle: true
        }
    },
    methods: {
        showBreadcrumbItem(item) {
            const { meta = {} } = item
            return meta.breadcrumb !== false && item.meta.title
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.page-header {
 // padding: $padding;
  padding: 8px 10px;
  background: $component-background;

  &__title-box,
  &__main {
    margin-top: $padding;
  }

  &__title-box {
    line-height: 1;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  &__title {
    float: left;
    font-size: $font-size*2;
    margin: 0;
  }

  &__action {
    float: right;
    margin-left: $padding;
  }
}
</style>
