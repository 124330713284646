/**
 * 报告管理--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 保前报告订单列表--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 保前报告订单列表-导出
export const RISK_CONTROL_BEFORE_REPORT_LIST_EXPORT_REPORT_LIST_BUTTON = 'RISK_CONTROL_BEFORE_REPORT_LIST_EXPORT_REPORT_LIST_BUTTON'

/**
 * 订单详情--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

// 保前报告订单列表-详细信息-查看订单
export const RISK_CONTROL_BEFORE_REPORT_LOOK_ORDER_BUTTON = 'RISK_CONTROL_BEFORE_REPORT_LOOK_ORDER_BUTTON'

// 保前报告订单列表-详细信息-下载报告
export const RISK_CONTROL_BEFORE_REPORT_DOWN_REPORT_BUTTON = 'RISK_CONTROL_BEFORE_REPORT_DOWN_REPORT_BUTTON'
