<template>
    <a-modal
        class="custom-modal"
        centered
        destroy-on-close
        v-bind="customProps"
        @cancel="handleCancel">
        <!-- 按钮 start -->
        <template slot="footer">
            <!-- 自定义footer -->
            <div v-if="$slots.customFooter">
                <slot name="customFooter" />
            </div>
            <!-- 初始化footer -->
            <div v-else>
                <a-button key="reset" @click="handleReset">
                    重置
                </a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                    提交
                </a-button>
            </div>
        </template>
        <!-- 按钮 end -->

        <slot />
    </a-modal>
</template>

<script>
// 组件
import {
    Modal as AModal,
    Button as AButton
} from 'ant-design-vue'

export default {
    name: 'CustomModal',
    components: {
        AModal,
        AButton
    },
    props: {
        footer: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 520
        }
    },
    data() {
        return {
            maskClosable: false
        }
    },
    computed: {
        customProps() {
            const { $props } = this
            const { footer, ...props } = $props
            return footer ? props : $props
        }
    },
    methods: {
        /**
         * 提交按钮事件
         */
        handleOk() {
            this.$emit('ok')
        },
        /**
         * 重置按钮事件
         */
        handleReset() {
            this.$emit('reset')
        },
        /**
         * 关闭弹框
         */
        handleCancel() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.custom-modal {
    .ant-modal-footer {
        text-align: center
    }
}
</style>
