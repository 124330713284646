import moment from 'moment'
import { getDaysBetween } from './'

// 按开始时间进行排序
export function sortByStartTime(list = []) {
    return list.sort((
        {
            startTime: startTime1
        },
        {
            startTime: startTime2
        }
    ) => Date.parse(startTime1) - Date.parse(startTime2))
}

// 将日期转为其字符串形式
export function formatDateToString(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date).format(format)
}

// 验证一组日期范围是否连续
export function validateDateRangeContinuity(list = []) {
    return new Promise((resolve, reject) => {
        let listLen = list.length
        if (listLen > 1) {
            list = sortByStartTime(list)
        }

        let index = 1
        while (index < listLen) {
            let { endTime: lastEndDate } = list[index - 1]
            let { startTime: nextStartTime } = list[index]

            lastEndDate = formatDateToString(lastEndDate, 'YYYY-MM-DD 23:59:59')
            nextStartTime = formatDateToString(nextStartTime, 'YYYY-MM-DD 00:00:00')

            let day = Math.floor(getDaysBetween(lastEndDate, nextStartTime))

            if (day !== 0) {
                reject(day)
            }

            index++
        }

        resolve(list)
    })
}
