<template>
    <a-card class="container-panel" :bordered="false" :class="{noCardBodyPaddingTop}">
        <!-- 标题 start -->
        <template v-if="$slots.title" slot="title">
            <slot name="title" />
        </template>
        <!-- 标题 end -->

        <!-- 操作按钮 start -->
        <template v-if="$slots.button" slot="extra">
            <div class="container-panel__button-group">
                <slot name="button" />
            </div>
        </template>
        <!-- 操作按钮 end -->

        <!-- 表格 start -->
        <div v-if="$slots.content" class="container-panel__content">
            <div class="container-panel__content-container">
                <slot name="content" />
            </div>
        </div>
        <!-- 表格 end -->

        <slot />
    </a-card>
</template>

<script>
import { Card as ACard } from 'ant-design-vue'

export default {
    name: 'PagePanel',
    components: {
        ACard
    },
    props: {
        // 该字段解决标题和content插槽内容距离过大
        noCardBodyPaddingTop: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
    @import '@assets/styles/varibles.scss';

    .container-panel {
      //min-width: 1200px;
      //overflow-x: scroll;
      border-radius: $border-radius-base;
      //box-shadow: $component-box-shadow;
        .ant-card-head {
            border-bottom: none;
        }
        &:not(:last-child) {
            margin-bottom: $padding;
        }

        /*操作按钮*/
        &__button-group {
            .ant-btn:not(:last-child) {
                margin-right: $padding;
            }
        }
    }
    .noCardBodyPaddingTop {
      .ant-card-body {
        padding: 0 $ant-card-body-padding $ant-card-body-padding;
      }
    }
</style>
