export default {
    methods: {
        // target需要回显的对象 origin为form数据对象
        initForm(target, origin) {
            Object.keys(origin).forEach(key => {
                let val = target[key]
                val = ['', null].indexOf(val) !== -1 ? undefined : val
                origin[key] = val
            })
        }
    }
}
