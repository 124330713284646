<script>
import { Button as AButton } from 'ant-design-vue'

export default {
    name: 'CustomButton',
    components: {
        AButton
    },
    props: {
    // 类别
        type: {
            type: String,
            required: false,
            default: 'default'
        },
        // 行为
        act: {
            type: String,
            required: false,
            default: ''
        },
        // 尺寸
        size: {
            type: String,
            required: false,
            default: 'default'
        },
        // 文本
        text: {
            type: String,
            required: true,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            const { type } = this
            return `custom-button__${type}`
        },
        props() {
            const { $props } = this
            const { ...props } = $props
            return props
        },
        listeners() {
            return {
                ...this.$listeners
                // 点击事件
                // click: (event) => {
                //     this.$emit('click', event, this.act)
                // }

            }
        }
    },
    methods: {},
    render(h) {
        return h('AButton', {
            class: this.className,
            on: this.listeners,
            props: this.props,
            scopedSlots: this.scopedSlots || this.$scopedSlots
        }, [this.$slots.expansion, this.text])
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';


.custom-button {

  /*操作按钮--默认*/
  &__default {
    border: 1px solid $custom-button-default-border-color;
    color: $custom-button-default-text-color;
  }

  /*操作按钮--重置*/
  &__reset {
    background-color: $custom-button-reset-background;
    border: 1px solid $custom-button-reset-border-color;
    color: $custom-button-reset-text-color;
  }
}
</style>
