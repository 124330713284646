/**
 * 任务管理--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 任务详情--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 任务详情-下载资料
export const TASK_INFO_DOWNLOAD_MATERIAL_BUTTON = 3200
// 任务详情-风控审核
export const TASK_INFO_RISK_CONTROL_AUDIT_BUTTON = 3200
// 任务详情-关闭订单
export const TASK_INFO_CLOSE_ORDER_BUTTON = 3200
// 任务详情-操作栏-转发任务
export const TASK_INFO_FORWARD_TASK_BUTTON = 3200
// 任务详情-操作栏-接受任务
export const TASK_INFO_ACCEPT_TASK_BUTTON = 3200
// 任务详情-操作栏-拒绝任务
export const TASK_INFO_REJECT_TASK_BUTTON = 3200
// 任务详情-下载报告
export const TASK_INFO_DOWN_LOAD_REPORT_BUTTON = 'TASK_INFO_DOWN_LOAD_REPORT_BUTTON'

// 任务详情-选择财务数据
export const TASK_INFO_SELECT_FINANCE_REPORT_BUTTON = 'TASK_INFO_EDIT_RISK_GRADE_MENU_CODE'
// 任务详情-修改风控信息
export const TASK_INFO_EDIT_RISK_CONTROL_BUTTON = 'TASK_INFO_EDIT_RISK_GRADE_MENU_CODE'

// 任务详情-风控信息-查看报表
export const RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON = 'RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON'
// 任务详情-风控信息-添加报表
export const RISK_CONTROL_INFO_ADD_FINANCIAL_BUTTON = 'RISK_CONTROL_INFO_ADD_FINANCIAL_BUTTON'
// 任务详情-风控信息-获取报表
export const RISK_CONTROL_INFO_COPY_FINANCIAL_BUTTON = 'RISK_CONTROL_INFO_COPY_FINANCIAL_BUTTON'
// 任务详情-风控信息-修改报表
export const RISK_CONTROL_INFO_EDIT_FINANCIAL_BUTTON = 'RISK_CONTROL_INFO_EDIT_FINANCIAL_BUTTON'
// 任务详情-风控信息-删除报表
export const RISK_CONTROL_INFO_DELETE_FINANCIAL_BUTTON = 'RISK_CONTROL_INFO_DELETE_FINANCIAL_BUTTON'
// 任务详情-修改风险评分-查看更多 历史评分
export const RISK_CONTROL_INFO_LOOK_HISTORY_SCORE_BUTTON = 'RISK_CONTROL_INFO_LOOK_HISTORY_SCORE_BUTTON'
// 查看订单
export const RISK_HISTORY_SCORE_LOOK_ORDER_BUTTON = 'RISK_HISTORY_SCORE_LOOK_ORDER_BUTTON'

// 任务指派
export const TASK_DESIGNATE_BUTTON = 'TASK_DESIGNATE_BUTTON'


