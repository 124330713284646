/**
 * 前端枚举维护
 * ————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 **/

/**
 * 通用枚举start
 * */

/**
 * 提交接口枚举
 *  PASS(1,"审核通过"),
    NO_PASS(2,"审核不通过"),
 ISSUE_REPORT(3,"报告签发"),
 NO_ISSUE_REPORT(4,"拒保"),
 CLOSE(5,"订单关闭")
 * */
export const AUDIT_TYPE_PASS = 1
export const AUDIT_TYPE_NO_PASS = 2
export const AUDIT_TYPE_ISSUE_REPORT = 3
export const AUDIT_TYPE_NO_ISSUE_REPORT = 4
export const AUDIT_TYPE_CLOSE = 5

/**
 * 业务类型
 * */
export const BUSINESS_TYPE_BEFORE_VALUE = 1
export const BUSINESS_TYPE_AFTER_VALUE = 2
export const BUSINESS_TYPE_BEFORE_LABEL = '保前风控业务'
export const BUSINESS_TYPE_AFTER_LABEL = '保后风控业务'
export const BUSINESS_TYPE_ENUM_LIST = [
    {
        value: BUSINESS_TYPE_BEFORE_VALUE,
        label: BUSINESS_TYPE_BEFORE_LABEL,
        disabled: false
    }, {
        value: BUSINESS_TYPE_AFTER_VALUE,
        label: BUSINESS_TYPE_AFTER_LABEL,
        disabled: true
    }
]
export const BUSINESS_TYPE_MAP = {
    [BUSINESS_TYPE_BEFORE_VALUE]: BUSINESS_TYPE_BEFORE_LABEL,
    [BUSINESS_TYPE_AFTER_VALUE]: BUSINESS_TYPE_AFTER_LABEL
}
/**
 * 规则集分类
 * A1保司通用自核规则——（原A1）
 A2保司自有自核规则
 A3保司自有快速核保规则——（原A2）
 * */
export const RULE_SET_TYPE_A1_LABEL = 'A1保司通用自核规则'
export const RULE_SET_TYPE_A1_VALUE = 1
export const RULE_SET_TYPE_A2_LABEL = 'A2保司自有自核规则'
export const RULE_SET_TYPE_A2_VALUE = 3
export const RULE_SET_TYPE_A3_LABEL = 'A3保司自有快速核保规则'
export const RULE_SET_TYPE_A3_VALUE = 2
export const RULE_SET_TYPE_ENUM_LIST = [
    {
        value: RULE_SET_TYPE_A1_VALUE,
        label: RULE_SET_TYPE_A1_LABEL
    }, {
        value: RULE_SET_TYPE_A2_VALUE,
        label: RULE_SET_TYPE_A2_LABEL
    }, {
        value: RULE_SET_TYPE_A3_VALUE,
        label: RULE_SET_TYPE_A3_LABEL
    }
]
export const RULE_SET_TYPE_MAP = {
    [RULE_SET_TYPE_A1_VALUE]: RULE_SET_TYPE_A1_LABEL,
    [RULE_SET_TYPE_A3_VALUE]: RULE_SET_TYPE_A3_LABEL,
    [RULE_SET_TYPE_A2_VALUE]: RULE_SET_TYPE_A2_LABEL
}

/**
 * 产品分类树 请求参数字段值
 * */
// 未开启
export const PRODUCT_CATEGORY_TREE_ENABLE_CLOSE = 0
// 已开启
export const PRODUCT_CATEGORY_TREE_ENABLE_OPEN = 1
// 全部
export const PRODUCT_CATEGORY_TREE_ENABLE_ALL = 2

/**
 * 启用状态/不启用
 * */
export const DATA_STATE_TYPE_NO_USED = 0
export const DATA_STATE_TYPE_USED = 1
export const isUsedFn = function (used) {
    return used === DATA_STATE_TYPE_USED
}
export const STATE_SWITCH_ENUM = [
    {
        label: '启用',
        value: DATA_STATE_TYPE_USED
    }, {
        label: '关闭',
        value: DATA_STATE_TYPE_NO_USED
    }
]
/**
 * 审核状态
 * */
// 未提交
export const DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT = 1
export const DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT_LABEL = '未提交审核'
export const DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT_ENUM = {
    label: DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT_LABEL,
    value: DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT
}
// 待审核
export const DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT = 2
export const DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT_LABEL = '待审核'
export const DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT_ENUM = {
    label: DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT_LABEL,
    value: DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT
}
// 驳回
export const DATA_CHECK_MODEL_AUDIT_STATE_REJECT = 3
export const DATA_CHECK_MODEL_AUDIT_STATE_REJECT_LABEL = '审核未通过'
export const DATA_CHECK_MODEL_AUDIT_STATE_REJECT_ENUM = {
    label: DATA_CHECK_MODEL_AUDIT_STATE_REJECT_LABEL,
    value: DATA_CHECK_MODEL_AUDIT_STATE_REJECT
}
// 审核通过
export const DATA_CHECK_MODEL_AUDIT_STATE_PASS = 4
export const DATA_CHECK_MODEL_AUDIT_STATE_PASS_LABEL = '审核通过'
export const DATA_CHECK_MODEL_AUDIT_STATE_PASS_ENUM = {
    label: DATA_CHECK_MODEL_AUDIT_STATE_PASS_LABEL,
    value: DATA_CHECK_MODEL_AUDIT_STATE_PASS
}
// 拒绝
export const DATA_CHECK_MODEL_AUDIT_STATE_REFUSE = 5
export const DATA_CHECK_MODEL_AUDIT_STATE_REFUSE_LABEL = '拒绝'
export const DATA_CHECK_MODEL_AUDIT_STATE_REFUSE_ENUM = {
    label: DATA_CHECK_MODEL_AUDIT_STATE_REFUSE_LABEL,
    value: DATA_CHECK_MODEL_AUDIT_STATE_REFUSE
}
export const AUDIT_STATE_ENUM_LIST = [
    DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT_ENUM,
    DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT_ENUM,
    DATA_CHECK_MODEL_AUDIT_STATE_REJECT_ENUM,
    DATA_CHECK_MODEL_AUDIT_STATE_PASS_ENUM,
    DATA_CHECK_MODEL_AUDIT_STATE_REFUSE_ENUM
]
export const AUDIT_STATE_MAP = {
    [DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT]: DATA_CHECK_MODEL_AUDIT_STATE_NOT_SUBMIT_LABEL,
    [DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT]: DATA_CHECK_MODEL_AUDIT_STATE_AWAIT_AUDIT_LABEL,
    [DATA_CHECK_MODEL_AUDIT_STATE_REJECT]: DATA_CHECK_MODEL_AUDIT_STATE_REJECT_LABEL,
    [DATA_CHECK_MODEL_AUDIT_STATE_PASS]: DATA_CHECK_MODEL_AUDIT_STATE_PASS_LABEL,
    [DATA_CHECK_MODEL_AUDIT_STATE_REFUSE]: DATA_CHECK_MODEL_AUDIT_STATE_REFUSE_LABEL
}

/**
 * 布局类型
 * layoutType
 * */
// 数据核验布局
export const DATA_CHECK_MODEL_LAYOUT_TYPE = 1
// 风险评估模型布局
export const RISK_ASSESS_MODEL_LAYOUT_TYPE = 2

/** 符号
 * */
export const GREATER_THAN_TYPE = 1
export const GREATER_THAN_TYPE_ENUM = {
    label: '大于',
    value: GREATER_THAN_TYPE,
    text: '(',
    comparator: '>'
}
export const GREATER_THAN_OR_EQUAL_TO_TYPE = 2
export const GREATER_THAN_OR_EQUAL_TO_TYPE_ENUM = {
    label: '大于等于',
    value: GREATER_THAN_OR_EQUAL_TO_TYPE,
    text: '[',
    comparator: '≥'
}
export const LESS_THAN_TYPE = 3
export const LESS_THAN_TYPE_ENUM = {
    label: '小于',
    value: LESS_THAN_TYPE,
    text: ')',
    comparator: '<'
}
export const LESS_THAN_OR_EQUAL_TO_TYPE = 4
export const LESS_THAN_OR_EQUAL_TO_TYPE_ENUM = {
    label: '小于等于',
    value: LESS_THAN_OR_EQUAL_TO_TYPE,
    text: ']',
    comparator: '≤'
}
export const GREATER_THAN_TYPE_ENUM_LIST = [GREATER_THAN_TYPE_ENUM, GREATER_THAN_OR_EQUAL_TO_TYPE_ENUM]
export const LESS_THAN_TYPE_ENUM_LIST = [LESS_THAN_TYPE_ENUM, LESS_THAN_OR_EQUAL_TO_TYPE_ENUM]
export const GREATER_AND_LESS_THAN_TYPE_ENUM_LIST = [
    GREATER_THAN_TYPE_ENUM,
    GREATER_THAN_OR_EQUAL_TO_TYPE_ENUM,
    LESS_THAN_TYPE_ENUM,
    LESS_THAN_OR_EQUAL_TO_TYPE_ENUM
]


// OSS上传枚举
export const OSS_PACKAGE_TYPE_RISK_STOCK_TYPE = 1 // 风控采购上传
export const OSS_PACKAGE_TYPE_RULE_ENGINE_TYPE = 2 // 规则引擎上传


// 按钮权限枚举
export const ORDER_BACK_AUTH_BUTTON = 1 // 退单审核
export const REPORT_RESIGN_AUTH_BUTTON = 2 // 报告重签审核
export const RULE_ENGINE_DELETE_BUTTON = 3 // 规则引擎删除
/**
 * 通用枚举end
 * */

// 评分卡
export const PROJECT_SCORE_CARD_VALUE = 2
const PROJECT_SCORE_CARD_LABEL = '特征工程评分卡'
export const EXPERT_SCORE_CARD_VALUE = 1
const EXPERT_SCORE_CARD_LABEL = '专家经验评分卡'
export const SCORE_CARD_MAP = {
    [PROJECT_SCORE_CARD_VALUE]: PROJECT_SCORE_CARD_LABEL,
    [EXPERT_SCORE_CARD_VALUE]: EXPERT_SCORE_CARD_LABEL
}
export const SCORE_CARD_ENUM_LIST = [
    {
        label: PROJECT_SCORE_CARD_LABEL,
        value: PROJECT_SCORE_CARD_VALUE
    }, {
        label: EXPERT_SCORE_CARD_LABEL,
        value: EXPERT_SCORE_CARD_VALUE
    }
]
export const IS_WEIGHT_TYPE = 1
export const NOT_IS_WEIGHT_TYPE = 0
export const WEIGHT_ENUM_LIST = [
    {
        label: '权重',
        value: IS_WEIGHT_TYPE
    }, {
        label: '非权重',
        value: NOT_IS_WEIGHT_TYPE
    }
]
export const WEIGHT_MAP = {
    [IS_WEIGHT_TYPE]: '权重',
    [NOT_IS_WEIGHT_TYPE]: '非权重'
}
/**
 * 消息
 * */
export const NOT_READE = 0
export const IS_READE = 1
export const NEW_TASK_REMINDER_TYPE_ENUM = {
    label: '新任务提醒',
    value: 1
}
export const AUDIT_REJECTION_REMINDER_TYPE_ENUM = {
    label: '任务驳回提醒',
    value: 2
}
export const TASK_AGENT_REMINDER_TYPE_ENUM = {
    label: '任务代理提醒',
    value: 3
}
export const TASK_REJECTION_REMINDER_TYPE_ENUM = {
    label: '任务拒绝提醒',
    value: 4
}

export const ORDER_RETURN_REMINDER_TYPE_ENUM = {
    label: '订单退单结果提醒',
    value: 5
}
export const ORDER_UNDERWRITING_REMINDER_TYPE_ENUM = {
    label: '订单承保提醒',
    value: 6
}
export const ORDER_REJECTION_REMINDER_TYPE_ENUM = {
    label: '订单驳回提醒',
    value: 7
}
export const ORDER_CLOSURE_REMINDER_TYPE_ENUM = {
    label: '订单关闭提醒',
    value: 8
}
export const ORDER_REPORT_RESIGN_REMINDER_TYPE = 9
export const ORDER_REPORT_RESIGN_REMINDER_TYPE_ENUM = {
    label: '报告重签提醒',
    value: ORDER_REPORT_RESIGN_REMINDER_TYPE
}
export const ORDER_CHARGE_BACK_AUDIT_TYPE = 10
export const ORDER_CHARGE_BACK_AUDIT_TYPE_ENUM = {
    label: '退单审核提醒',
    value: ORDER_CHARGE_BACK_AUDIT_TYPE
}
// 跳转订单详情页
export const IS_PUSH_ORDER_INFO_LIST = [ORDER_REPORT_RESIGN_REMINDER_TYPE_ENUM, ORDER_CHARGE_BACK_AUDIT_TYPE_ENUM]
// 跳转风控采购
export const ORDER_RISK_PROCUREMENT_TYPE_ENUM_LIST = [
    ORDER_RETURN_REMINDER_TYPE_ENUM,
    ORDER_UNDERWRITING_REMINDER_TYPE_ENUM,
    ORDER_REJECTION_REMINDER_TYPE_ENUM,
    ORDER_CLOSURE_REMINDER_TYPE_ENUM
]
export const MESSAGE_TYPE_ENUM_LIST = [
    NEW_TASK_REMINDER_TYPE_ENUM,
    AUDIT_REJECTION_REMINDER_TYPE_ENUM,
    TASK_AGENT_REMINDER_TYPE_ENUM,
    TASK_REJECTION_REMINDER_TYPE_ENUM,
    ...ORDER_RISK_PROCUREMENT_TYPE_ENUM_LIST,
    ...IS_PUSH_ORDER_INFO_LIST
]

/**
 * 模型
* */
export const DATA_CHECK_MODEL = 'DATA_CHECK_MODEL'
export const GRADE_MODEL = 'GRADE_MODEL'
export const RISK_ASSESS_MODEL = 'RISK_ASSESS_MODEL'
export const RISK_CLASS_MODEL = 'RISK_CLASS_MODEL'
export const SMART_ASSESS_MODEL = 'SMART_ASSESS_MODEL'
export const RULE_SET_MODEL = 'RULE_SET_MODEL'


// 是否审计
export const FINANCE_REPORT_IS_AUDIT_VALUE = 1
export const FINANCE_REPORT_IS_AUDIT_LABEL = '是'
export const FINANCE_REPORT_IS_AUDIT_ENUM = {
    label: FINANCE_REPORT_IS_AUDIT_LABEL,
    value: FINANCE_REPORT_IS_AUDIT_VALUE
}
export const FINANCE_REPORT_NOT_AUDIT_VALUE = 0
export const FINANCE_REPORT_NOT_AUDIT_LABEL = '否'
export const FINANCE_REPORT_NOT_AUDIT_ENUM = {
    label: FINANCE_REPORT_NOT_AUDIT_LABEL,
    value: FINANCE_REPORT_NOT_AUDIT_VALUE
}
export const FINANCE_REPORT_AUDIT_ENUM_LIST = [FINANCE_REPORT_IS_AUDIT_ENUM, FINANCE_REPORT_NOT_AUDIT_ENUM]

/**
 * 风控采购-订单列表-订单状态
 */
// 全部/已提交
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_TYPE = ''
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_LABEL = '已提交'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_TYPE
}
// 待提交
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_TYPE = 0
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_LABEL = '待提交'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_TYPE
}
// 待提交
// export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_TYPE = 0
// export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_LABEL = '待提交'
// export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_ENUM = {
//     label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_LABEL,
//     value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_TYPE
// }
// 待评估
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE = 1
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_LABEL = '待评估'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE
}
// 退单审核
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_TYPE = 2
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_LABEL = '退单审核'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_TYPE
}
// 已关闭
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_TYPE = 3
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_LABEL = '已关闭'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_TYPE
}
// 待承保
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE = 4
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_LABEL = '待承保'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE
}
// 已承保
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_TYPE = 5
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_LABEL = '已承保'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_TYPE
}
// 已拒保
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_TYPE = 6
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_LABEL = '已拒保'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_TYPE
}
// 已驳回
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_TYPE = 7
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_LABEL = '已驳回'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_TYPE
}
// 退单关闭
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_TYPE = 8
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_LABEL = '退单关闭  '
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_TYPE
}
// 报告重签
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_SIGN = 9
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_LABEL = '报告重签'
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_SIGN
}
export const RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST = [
    // RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_SUBMIT_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_INSURED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_DISAPPROVED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CHARGEBACK_REVIEW_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_ENUM,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REPORT_RE_ENUM
]

// 是否承保
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE = 1
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_LABEL = '是'
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_LABEL,
    value: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE
}
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_TYPE = 2
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_LABEL = '否'
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_LABEL,
    value: RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_TYPE
}
export const RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_ENUM_LIST = [RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_ENUM, RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_ENUM]

/**
 * 险种名称-枚举
 * */
// 工程投标保证保险
export const RISK_CONTROL_PROCUREMENT_TENDER_TYPE = 1
export const RISK_CONTROL_PROCUREMENT_TENDER_LABEL = '工程投标保证保险'
export const RISK_CONTROL_PROCUREMENT_TENDER_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_TENDER_LABEL,
    value: RISK_CONTROL_PROCUREMENT_TENDER_TYPE
}
// 合同履约保证保险
export const RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE = 2
export const RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL = '合同履约保证保险'
export const RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL,
    value: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE
}
// 农民工工资支付履约保证保险/企业版
export const RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE = 3
export const RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL = '农民工工资支付履约保证保险/企业版'
export const RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL,
    value: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE
}
// 农民工工资支付履约保证保险/项目版
export const RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE = 4
export const RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL = '农民工工资支付履约保证保险/项目版'
export const RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL,
    value: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE
}

// 建设工程预付款履约保证保险
export const RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE = 5
export const RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL = '建设工程预付款履约保证保险'
export const RISK_CONTROL_PROCUREMENT_ADVANCE_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL,
    value: RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE
}

// 建设工程质量保证保险
export const RISK_CONTROL_PROCUREMENT_QUALITY_TYPE = 6
export const RISK_CONTROL_PROCUREMENT_QUALITY_LABEL = '建设工程质量保证保险'
export const RISK_CONTROL_PROCUREMENT_QUALITY_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_QUALITY_LABEL,
    value: RISK_CONTROL_PROCUREMENT_QUALITY_TYPE
}

// 建设工程合同款支付保证保险
export const RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE = 7
export const RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL = '建设工程合同款支付保证保险'
export const RISK_CONTROL_PROCUREMENT_CONTRACT_ENUM = {
    label: RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL,
    value: RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE
}

export const RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_MAP = {
    [RISK_CONTROL_PROCUREMENT_TENDER_TYPE]: RISK_CONTROL_PROCUREMENT_TENDER_LABEL,
    [RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE]: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL,
    [RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE]: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL,
    [RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE]: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL,
    [RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE]: RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL,
    [RISK_CONTROL_PROCUREMENT_QUALITY_TYPE]: RISK_CONTROL_PROCUREMENT_QUALITY_LABEL,
    [RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE]: RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL
}
export const RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST = [
    RISK_CONTROL_PROCUREMENT_TENDER_ENUM,
    RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_ENUM,
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_ENUM,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_ENUM,
    RISK_CONTROL_PROCUREMENT_ADVANCE_ENUM,
    RISK_CONTROL_PROCUREMENT_QUALITY_ENUM
    // RISK_CONTROL_PROCUREMENT_CONTRACT_ENUM
]

/**
 * 任务管理
 * */
// 我的待办
export const TASK_AWAIT_TODO_TYPE = '1'
// 任务代理
export const TASK_PROXY_TYPE = '2'
// 已办任务
export const TASK_HAVE_DOWN_TYPE = '3'

/**
 评分模型-评分因子类型
 **/
// 基本类型 1
export const GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE = 1
export const GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE_ENUM = {
    label: '基本类型',
    value: GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE
}
export const GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE = 2
export const GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_ENUM = {
    label: '数值区间类型',
    value: GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE
}
export const GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE = 3
export const GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE_ENUM = {
    label: '文本类型',
    value: GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE
}
export const GRADE_FACTOR_TYPE_ENUM_LIST = [GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE_ENUM, GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_ENUM, GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE_ENUM]
export const GRADE_FACTOR_TYPE_ENUM_LIST2 = [GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE_ENUM, GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_ENUM]

// 数值区间最大值
export const GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_MAX_VALUE = 9999999999.99


/**
 * 风险评估
 * */
// 评估内容类型
export const RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE = 1
export const RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE_ENUM = {
    label: '全国通用',
    value: RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE
}
export const RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE = 2
export const RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE_ENUM = {
    label: '地区业务',
    value: RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE
}
export const RISK_ASSESSMENT_CONTENT_TYPE_ENUM_LIST = [RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE_ENUM, RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE_ENUM]


/**
 * 风险因子
 * */
// 因子类型：文本类型
export const RISK_FACTOR_DATA_TYPE_TXT_TYPE = 'TXT' // 文本输入
export const RISK_FACTOR_DATA_TYPE_URL_TYPE = 'URL' // url文本输入
export const RISK_FACTOR_DATA_TYPE_BOOL_TYPE = 'BOOL' // 布尔型 单选
export const RISK_FACTOR_DATA_TYPE_DATE_TYPE = 'DATE' // 日期
export const RISK_FACTOR_DATA_TYPE_DATE_RANGE_TYPE = 'DATE_RANGE' // 日期范围
export const RISK_FACTOR_DATA_TYPE_TEL_TYPE = 'TEL' // 座机
export const RISK_FACTOR_DATA_TYPE_PHONE_TYPE = 'PHONE' // 手机
export const RISK_FACTOR_DATA_TYPE_PID_TYPE = 'PID' // 身份证
export const RISK_FACTOR_DATA_TYPE_CNO_TYPE = 'CNO' // 统一社会信用代码
export const RISK_FACTOR_DATA_TYPE_MAIL_TYPE = 'MAIL' // 邮箱
export const RISK_FACTOR_DATA_TYPE_ADDRESS_TYPE = 'ADDRESS' // 地址
// 因子类型：有这两个类型的因子有属性值
export const RISK_FACTOR_DATA_TYPE_DICT_TYPE = 'DICT'
export const RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE = 'DICT_CASCADE'
export const RISK_FACTOR_DATA_TYPE_NUM_TYPE = 'NUM' //   NUM("NUM","数字"),
export const RISK_FACTOR_DATA_TYPE_MONEY_TYPE = 'MONEY' //   MONEY("MONEY","金额"),
export const RISK_FACTOR_DATA_TYPE_FLOAT_TYPE = 'FLOAT' //    FLOAT("FLOAT","浮点数"),
export const RISK_FACTOR_DATA_TYPE_FINANCIAL_REPORT_TYPE = 'FINANCIAL_REPORT' //   FINANCIAL_REPORT("FINANCIAL_REPORT","财务报表"),
export const RISK_FACTOR_DATA_TYPE_CREDIT_REPORT_TYPE = 'CREDIT_REPORT' //    CREDIT_REPORT("CREDIT_REPORT","征信报告"),
export const RISK_FACTOR_DATA_TYPE_DATA_SECTION_OBJ = {
    [RISK_FACTOR_DATA_TYPE_NUM_TYPE]: RISK_FACTOR_DATA_TYPE_NUM_TYPE,
    [RISK_FACTOR_DATA_TYPE_MONEY_TYPE]: RISK_FACTOR_DATA_TYPE_MONEY_TYPE,
    [RISK_FACTOR_DATA_TYPE_FLOAT_TYPE]: RISK_FACTOR_DATA_TYPE_FLOAT_TYPE,
    [RISK_FACTOR_DATA_TYPE_FINANCIAL_REPORT_TYPE]: RISK_FACTOR_DATA_TYPE_FINANCIAL_REPORT_TYPE,
    [RISK_FACTOR_DATA_TYPE_CREDIT_REPORT_TYPE]: RISK_FACTOR_DATA_TYPE_CREDIT_REPORT_TYPE
}
// export const RISK_FACTOR_DATA_TYPE_TXT_TYPE


/**
 * 数据核验模型因子
 * 限制节点
 * */
// 不作为限制显示
export const DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE = 1
// 作为限制显示
export const DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE = 2

/**
 * 订单状态
 * business_state    `business_state` tinyint(4) DEFAULT '1' COMMENT '状态（1：初始状态，2：报告签发，3：拒保，4：关闭）',
 * */
export const ORDER_BUSINESS_STATE_INIT_STATE_TYPE = 1
export const ORDER_BUSINESS_STATE_INIT_STATE_TYPE_ENUM = {
    label: '初始状态',
    value: ORDER_BUSINESS_STATE_INIT_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_SING_STATE_TYPE = 2
export const ORDER_BUSINESS_STATE_SING_STATE_TYPE_ENUM = {
    label: '报告签发',
    value: ORDER_BUSINESS_STATE_SING_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_REJECT_STATE_TYPE = 3
export const ORDER_BUSINESS_STATE_REJECT_STATE_TYPE_ENUM = {
    label: '拒保',
    value: ORDER_BUSINESS_STATE_REJECT_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE = 4
export const ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE_ENUM = {
    label: '订单关闭',
    value: ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE = 5
export const ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE_ENUM = {
    label: '撤销',
    value: ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE = 6
export const ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE_ENUM = {
    label: '报告重签',
    value: ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE
}
export const ORDER_BUSINESS_STATE_ENUM_LIST = [
    ORDER_BUSINESS_STATE_INIT_STATE_TYPE_ENUM,
    ORDER_BUSINESS_STATE_SING_STATE_TYPE_ENUM,
    ORDER_BUSINESS_STATE_REJECT_STATE_TYPE_ENUM,
    ORDER_BUSINESS_STATE_CLOSE_STATE_TYPE_ENUM,
    ORDER_BUSINESS_STATE_REVOCATION_STATE_TYPE_ENUM,
    ORDER_BUSINESS_STATE_RESIGN_STATE_TYPE_ENUM
]
/**
 * init=> state
* */
// 1：待识别（黑名单），2：待录入，3：待识别（风险提示），
// 4：信用评级，5：待评分评级，6：待评估（AI智能），7：待智能评估，21：待初审，22：待复审，23：待高审）',
export const ORDER_STATE_AWAIT_DISCERN_BLACK_LIST_STATE_TYPE = 1
export const ORDER_STATE_AWAIT_DISCERN_BLACK_LIST_STATE_TYPE_ENUM = {
    label: '待识别（黑名单）',
    value: ORDER_STATE_AWAIT_DISCERN_BLACK_LIST_STATE_TYPE
}
export const ORDER_STATE_AWAIT_ENTER_STATE_TYPE = 2
export const ORDER_STATE_AWAIT_ENTER_STATE_TYPE_ENUM = {
    label: '待录入',
    value: ORDER_STATE_AWAIT_ENTER_STATE_TYPE
}
export const ORDER_STATE_AWAIT_DISCERN_WARNING_STATE_TYPE = 3
export const ORDER_STATE_AWAIT_DISCERN_WARNING_STATE_TYPE_ENUM = {
    label: '待识别（风险提示）',
    value: ORDER_STATE_AWAIT_DISCERN_WARNING_STATE_TYPE
}
export const ORDER_STATE_CREDIT_CLASS_STATE_TYPE = 4
export const ORDER_STATE_CREDIT_CLASS_STATE_TYPE_ENUM = {
    label: '信用评级',
    value: ORDER_STATE_CREDIT_CLASS_STATE_TYPE
}
export const ORDER_STATE_AWAIT_GRADE_STATE_TYPE = 5
export const ORDER_STATE_AWAIT_GRADE_STATE_TYPE_ENUM = {
    label: '待评分评级',
    value: ORDER_STATE_AWAIT_GRADE_STATE_TYPE
}
export const ORDER_STATE_AWAIT_AI_ASSESS_STATE_TYPE = 6
export const ORDER_STATE_AWAIT_AI_ASSESS_STATE_TYPE_ENUM = {
    label: '待评估（AI智能）',
    value: ORDER_STATE_AWAIT_AI_ASSESS_STATE_TYPE
}
export const ORDER_STATE_AWAIT_SMART_ASSESS_STATE_TYPE = 7
export const ORDER_STATE_AWAIT_SMART_ASSESS_STATE_TYPE_ENUM = {
    label: '待智能评估',
    value: ORDER_STATE_AWAIT_SMART_ASSESS_STATE_TYPE
}
export const ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE = 21
export const ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE_ENUM = {
    label: '待初审',
    value: ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE
}
export const ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE = 22
export const ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE_ENUM = {
    label: '待复审',
    value: ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE
}
export const ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE = 23
export const ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE_ENUM = {
    label: '待高审',
    value: ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE
}
// 该订单代表不走风控
export const ORDER_STATE_NOT_RISK_CONTROL_STATE_TYPE = 100
export const ORDER_STATE_TYPE_ENUM_LIST = [
    ORDER_STATE_AWAIT_DISCERN_BLACK_LIST_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_ENTER_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_DISCERN_WARNING_STATE_TYPE_ENUM,
    ORDER_STATE_CREDIT_CLASS_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_GRADE_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_AI_ASSESS_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_SMART_ASSESS_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE_ENUM,
    ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE_ENUM
]


/**
 * 商品管理-保前商品-风控模型信息 modelType
 * */
// 订单提交
export const COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_TYPE = 1
export const COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_LABEL = '订单提交'

// 黑名单
export const COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE = 2
export const COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_LABEL = '黑名单'

// 数据核验模型
export const COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE = 3
export const COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_LABEL = '数据核验模型'

// 工保网数据核验模型
export const COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_GBW_MODEL_TYPE = 31
// 风险提示
export const COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_TYPE = 4
export const COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_LABEL = '风险提示'

// 企业信用评级
export const COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_TYPE = 5
export const COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_LABEL = '企业信用评级'

// 评分模型
export const COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE = 6
export const COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_LABEL = '评分模型'

// AI智能模型
export const COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_TYPE = 7
export const COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_LABEL = 'AI智能模型'

// 智能评估
export const COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE = 8
export const COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_LABEL = '智能评估'

// 人工审核
export const COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE = 21
export const COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_LABEL = '人工审核'

// 保司通用核保规则
export const COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE = 11
export const COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_LABEL = '保司通用核保规则'

// 保司自有快速核保规则
export const COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_TYPE = 31
export const COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_LABEL = '保司自有快速核保规则'

// 保司自有自核规则
export const COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_TYPE = 12
export const COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_LABEL = '保司自有自核规则'

// 特征工程评分模型
export const COMMODITY_CONFIG_PROJECT_SCORE_TYPE = 13
export const COMMODITY_CONFIG_PROJECT_SCORE_LABEL = '特征工程评分模型'

export const COMMODITY_CONFIG_MAP = {
    [COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_TYPE]: {
        key: 'blacklistMode', // 提交给后台 代表当前模型是否选中
        com: 'BlackList', // 组件名
        name: COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_LABEL// '订单提交'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE]: {
        key: 'blacklistMode',
        com: 'BlackList',
        name: COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_LABEL// '黑名单'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE]: {
        key: 'dataVerificationModel',
        com: 'DataCheckModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_LABEL// '数据核验模型'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_TYPE]: {
        key: 'riskWarningModel',
        com: 'RiskRemind',
        name: COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_LABEL// '风险提示'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_TYPE]: {
        key: 'enterpriseCreditRatingModel',
        com: 'EnterpriseModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_LABEL// '企业信用评级'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE]: {
        key: 'scoringModel',
        com: 'GradeModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_LABEL// '评分模型'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_TYPE]: {
        key: 'aiIntelligentModel',
        com: 'AiModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_LABEL// 'AI智能模型'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE]: {
        key: 'intelligentEvaluationModel',
        com: 'SmartAssess',
        name: COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_LABEL// '智能评估'
    },
    [COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE]: {
        key: 'manualAuditModel',
        com: 'PersonCheck',
        name: COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_LABEL// '人工审核'
    },
    // 保司通用核保规则
    [COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE]: {
        key: 'autoUwModel',
        com: 'AutoUnderModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_LABEL
    },
    // 保司自有快速核保规则
    [COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_TYPE]: {
        key: 'insuranceModel',
        com: 'InsureRuleModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_LABEL
    },
    // 保司自有自核规则
    [COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_TYPE]: {
        key: 'scInsuranceCompany',
        com: 'InsureA2RuleModel',
        name: COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_LABEL
    },
    [COMMODITY_CONFIG_PROJECT_SCORE_TYPE]: {
        key: 'characteristicEngineeringScoringModel',
        com: 'ProjectScoreModel',
        name: COMMODITY_CONFIG_PROJECT_SCORE_LABEL
    }
}

// 进度条可以查看意见的模型
export const CAN_LOOK_IDEA_MAP = {
    [COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE]: COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE,
    [ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE]: ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE,
    [ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE]: ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE,
    [ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE]: ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE,
    [COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_GBW_MODEL_TYPE]: COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_GBW_MODEL_TYPE
}

// 显示（详细信息，关联单，操作日志） 的模型 :待识别（黑名单） 待录入
export const LOOK_THREE_TABLE_LIST = [ORDER_STATE_AWAIT_DISCERN_BLACK_LIST_STATE_TYPE, ORDER_STATE_AWAIT_ENTER_STATE_TYPE]
// 显示（详细信息，风控信息，关联单，操作日志） 的模型 :待识别（风险提示）待评分评级 待评估（AI智能） 待智能评估 待初审
export const LOOK_FOUR_TABLE_LIST = [
    ORDER_STATE_AWAIT_DISCERN_WARNING_STATE_TYPE,
    ORDER_STATE_AWAIT_GRADE_STATE_TYPE,
    ORDER_STATE_AWAIT_AI_ASSESS_STATE_TYPE,
    ORDER_STATE_AWAIT_SMART_ASSESS_STATE_TYPE,
    ORDER_STATE_AWAIT_INIT_AUDIT_STATE_TYPE
]
// 显示（详细信息，风控信息，风控评估，关联单，操作日志） 的模型:待复审 待高审 已签发 已拒单
export const LOOK_FIVE_TABLE_LIST = [ORDER_STATE_AWAIT_AGAIN_AUDIT_STATE_TYPE, ORDER_STATE_AWAIT_HIGH_AUDIT_STATE_TYPE]


/**
 * 订单来源
 * */
export const ORDER_SOURCE_GBW_TYPE = 1
export const ORDER_SOURCE_GBW_LABEL = '工保网'
export const ORDER_SOURCE_GBW_ENUM = {
    label: ORDER_SOURCE_GBW_LABEL,
    value: ORDER_SOURCE_GBW_TYPE
}
export const ORDER_SOURCE_RISK_PROCUREMENT_TYPE = 2
export const ORDER_SOURCE_RISK_PROCUREMENT_LABEL = '工保盾'
export const ORDER_SOURCE_RISK_PROCUREMENT_ENUM = {
    label: ORDER_SOURCE_RISK_PROCUREMENT_LABEL,
    value: ORDER_SOURCE_RISK_PROCUREMENT_TYPE
}
export const ORDER_SOURCE_TYPE_ENUM_LIST = [ORDER_SOURCE_GBW_ENUM, ORDER_SOURCE_RISK_PROCUREMENT_ENUM]
export const ORDER_SOURCE_MAP = {
    [ORDER_SOURCE_GBW_TYPE]: ORDER_SOURCE_GBW_LABEL,
    [ORDER_SOURCE_RISK_PROCUREMENT_TYPE]: ORDER_SOURCE_RISK_PROCUREMENT_LABEL
}
export const FILL_TYPE_PROJECT_TYPE = {
    label: '项目资料',
    value: 1
}
export const FILL_TYPE_ENTERPRISE_TYPE = {
    label: '企业资料',
    value: 2
}
export const FILL_TYPE_ALL_TYPE = {
    label: '全部资料',
    value: 3
}
export const FILE_TYPE_ENUM = [FILL_TYPE_ALL_TYPE, FILL_TYPE_ENTERPRISE_TYPE, FILL_TYPE_PROJECT_TYPE]
/**
 * 承保意见
 * */
export const RESOLVE_INSURE_TYPE = {
    label: '建议承保',
    value: 1
}
export const WARNING_INSURE_TYPE = {
    label: '谨慎承保',
    value: 2
}
export const REJECT_INSURE_TYPE = {
    label: '拒绝承保',
    value: 3
}
export const INSURE_TYPE_ENUM = [RESOLVE_INSURE_TYPE, WARNING_INSURE_TYPE, REJECT_INSURE_TYPE]
/**
 * 承保结果
 * */
export const UNDERWRITE_TYPE = 1
export const NOT_UNDERWRITE_TYPE = 2
export const CLOSE_UNDERWRITE_TYPE = 3
export const UNDERWRITE_MAP = {
    [UNDERWRITE_TYPE]: '核保通过',
    [NOT_UNDERWRITE_TYPE]: '拒保',
    [CLOSE_UNDERWRITE_TYPE]: '关闭订单'
}
/**
 * 财报可输入枚举  '0：定义，无值，1：值对象，2：合计'
 * */
export const FINANCIAL_INIT = 0
export const FINANCIAL_OBJECT = 1
export const FINANCIAL_SUM = 2

/**
 * 风控采购订单提交退单
 * 订单列表依赖此枚举显示审核按钮
 * */
export const REVOCATION_STATE_TYPE = 1

/**
 * 特殊处理
 * 财报/报告因子id code
 * */
export const REPORT_FACTOR_CODE = 'zxbgsftg'
export const REPORT_FACTOR_ID = '2a84efdc-2a7d-4b5c-904b-3e23f18b617f'

export const FINANCIAL_CODE = 'FINANCIAL_REPORT'
export const FINANCIAL_ID = '1366576139106672642'

export const REPORT_CODE = 'CREDIT_REPORT'
export const REPORT_ID = '1366576186682662913'
