/**
 * 审批及日志--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 规则审批--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 规则审核列表-查看
export const APPROVE_MANAGE_RULE_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON = 'APPROVE_MANAGE_RULE_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON'
// 规则审核详情-审核
export const APPROVE_MANAGE_RULE_APPROVE_DETAIL_AUDIT_BUTTON = 'APPROVE_MANAGE_RULE_APPROVE_DETAIL_AUDIT_BUTTON'
// 商品审核列表-查看
export const APPROVE_MANAGE_COMMODITY_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON = 'APPROVE_MANAGE_COMMODITY_APPROVE_LIST_LOOK_RULE_APPROVE_BUTTON'
// 商品审核详情-审核
export const APPROVE_MANAGE_COMMODITY_APPROVE_DETAIL_AUDIT_BUTTON = 'APPROVE_MANAGE_COMMODITY_APPROVE_DETAIL_AUDIT_BUTTON'
// 操作日志-审核
export const APPROVE_MANAGE_LOG_APPROVE_BUTTON = 'APPROVE_MANAGE_LOG_APPROVE_BUTTON'
