<template>
    <div class="radio-group-panel">
        <a-radio-group :default-value="defaultActiveKey" @change="onChange">
            <a-radio-button
                v-for="item in radioData"
                :key="item.key"
                :value="item.key"
                style="min-width: 100px"
            >
                {{ item.label }}
                <span v-if="item.key===defaultActiveKey && sum!==''">{{ sum }}</span>
            </a-radio-button>
        </a-radio-group>
    </div>
</template>

<script>
import { Radio as ARadio } from 'ant-design-vue'

export default {
    name: 'RadioGroupPanel',
    components: {
        ARadioGroup: ARadio.Group,
        ARadioButton: ARadio.Button
    },
    props: {
        radioData: {
            type: Array,
            default() {
                return []
            }
        },
        defaultActiveKey: {
            type: [String, Number],
            default: ''
        },
        sum: {
            type: [String, Number]
        }
    },
    methods: {
        onChange(key) {
            this.$emit('change', key)
        }
    }
}
</script>

<style lang="scss">
</style>
