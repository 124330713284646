<template>
    <div class="descriptions-with-head__container">
        <div class="descriptions-with-head__container__title">
            <div class="descriptions-with-head__container__title__text">{{ title }}</div>
            <div class="descriptions-with-head__container__button">
                <slot name="button" />
            </div>
        </div>

        <div v-if="$slots.default" class="descriptions-with-head__container__slot">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'DescriptionsHead',
    components: {},
    props: {
        title: {
            type: String,
            default() {
                return ''
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.descriptions-with-head__container {
  border-radius: $border-radius-base;
  border: 1px solid #f0f0f0;
  &__title {
    padding: 12px;
    background: #fafafa;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
  }
  &::after {
    clear: both;
  }
  &__title__text {
    display: inline-block;
    flex: 1;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }
  &__button {
    float: right;
    padding-right: 12px;
  }
  &__slot {
    padding: $space-base 12px
  }
  &:not(:last-child) {
    margin-bottom: $space-base;
  }
}

</style>
