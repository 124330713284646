<!-- 单个搜索框 -->
<template>
    <div class="single-search-panel">
        <a-form-model
            ref="form"
            :layout="layout"
            :model="model"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <slot />
            <a-button type="primary" class="single-search-panel__search-button" @click="handleSearch">查询</a-button>
        </a-form-model>
    </div>
</template>

<script>
import { FormModel as AFormModel, Button as AButton } from 'ant-design-vue'
import { filterParams } from '@utils/search.js'

export default {
    name: 'SingleSearchPanel',
    components: {
        AFormModel,
        AButton
    },
    props: {
        layout: {
            type: String,
            default: 'horizontal'
        },
        model: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 22
            }
        }
    },
    methods: {
        handleChange(value) {
            this.form.riskCategoryId = value
        },
        handleSearch() {
            const { model } = this
            let { ...params } = model
            this.$emit('search', filterParams(params))
        }
    }
}
</script>

<style lang="scss">
.single-search-panel {
  &__search-button {
    margin-top: 3px;
  }
}
</style>
