/**
 * 企业管理--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 企业列表--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 企业列表-查看
export const ENTERPRISE_MANAGE_ENTERPRISE_LIST_LOOK_ENTERPRISE_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_LIST_LOOK_ENTERPRISE_BUTTON'
// 企业列表-导出
export const ENTERPRISE_MANAGE_ENTERPRISE_LIST_EXPORT_ENTERPRISE_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_LIST_EXPORT_ENTERPRISE_BUTTON'

/**
 * 企业详情--code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 企业详情-财报信息-查看财报信息
export const ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_FINANCAIL_REPORT_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_FINANCAIL_REPORT_BUTTON'
// 企业详情-财报信息-查看财报信息
export const ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_BUTTON'
// 企业详情-征信报告-查看征信报告
export const ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_CREDIT_REPORT_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_CREDIT_REPORT_BUTTON'
// 企业详情-征信报告-资料影像-下载资料
export const ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_IMAGES_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_INFO_DOWNLOAD_MATERIAL_IMAGES_BUTTON'
// 企业详情-订单信息-查看订单信息
export const ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_ORDER_INFO_BUTTON = 'ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_ORDER_INFO_BUTTON'
