/**
 * 枚举操作相关方法
 */
export default {
    methods: {
        /**
         * 从枚举列表中获取字段拼接
         * @param list
         * @param field
         * @returns {(function(*=, *=): string)|(function(*=, *=): boolean)|string}
         */
        splitStringFromList(list = [], field) {
            let str = list.map(function ( obj ) {
                return obj[field]
            }).join(',')

            return str || ''
        },
        /**
         * 从枚举列表中获取描述字段
         * @param list
         * @param value
         * @param field 默认‘label’
         * @returns {(function(*=, *=): boolean)|(function(*=, *=): boolean)|string}
         */
        getApiEnumLabelFromList(list = [], value, field = 'label') {
            for (let i = 0; i < list.length; i++) {
                const item = list[i]
                if (item.value === value) {
                    return item[field]
                }
            }
        }
    }
}
