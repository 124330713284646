<!-- 是否启用 -->
<template>
    <a-select
        v-model="modelValue"
        placeholder="请选择"
        :allow-clear="clear"
        @change="handleChange">
        <a-select-option
            v-for="item in STATE_SWITCH_ENUM"
            :key="item.value"
            :value="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
import {
    STATE_SWITCH_ENUM
} from '@constant/enum'
import selectMixin from '../selectMixin'
export default {
    name: 'StateSelect',
    mixins: [selectMixin],
    data() {
        return {
            STATE_SWITCH_ENUM
        }
    }

}
</script>
