/**
 * 节流
 * @param {Function} fn
 * @param {Number}   wait time
 * @param {Boolean}  start 首次是否触发
 * @param {Boolean}  end 结束是否触发
 */
export function throttle(fn, wait, start, end) {
    let timer
    let previous = 0
    return function (...args) {
        let now = +new Date()
        // remaining 不触发下一次函数的剩余时间
        if (!previous && start === false) previous = now
        let remaining = wait - (now - previous)
        if (remaining < 0) {
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
            previous = now
            fn.apply(this, args)
        } else if (!timer && end !== false) {
            timer = setTimeout(() => {
                previous = start === false ? 0 : +new Date()
                timer = null
                fn.apply(this, args)
            }, remaining)
        }
    }
}

/**
 * 防抖
 * @param {Function} fn
 * @param {Number}   wait time
 * @param {Boolean}  immediate 是否立即触发
 * */
export function debounce(fn, wait, immediate) {
    let timer
    return function (...args) {
        if (timer) clearTimeout(timer)
        if (immediate) {
            let callNow = !timer
            timer = setTimeout(() => {
                timer = null
            }, wait)
            callNow && fn.call(this, ...args)
        } else {
            timer = setTimeout(fn.bind(this, ...args), wait)
        }
    }
}
